import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
import {convertPieceDtoToModel} from 'projects/dra-lib/src/lib/tracking/api/converter/convert-piece-dto-to-model';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {BatchApiService} from '../../api/batch-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {selectTimeZone} from '../workspace/workspace.selector';
import {AuthTrackingActionType, GetAuthPiecesAction, GetAuthPiecesSuccessAction} from './auth-tracking.action';

@Injectable()
export class AuthTrackingEffects {
  public getTrackingData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAuthPiecesAction>(AuthTrackingActionType.GET_PIECES),
      withLatestFrom(this.store$.pipe(select(selectTimeZone))),
      mergeMap(([action, timeZone]) => {
        const {searchValue, onSuccess, onFailure} = action.payload;

        return this.batchApiService.getAuthTrackingData(searchValue, timeZone).pipe(
          map(data => data?.Pieces?.map(dto => convertPieceDtoToModel(dto)) || []),
          mergeMap(pieces => [
            new GetAuthPiecesSuccessAction({pieces, searchValue}),
            ...createCallbackActions(onSuccess, pieces),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(private actions$: Actions, private batchApiService: BatchApiService, private store$: Store) {}
}
