/* eslint-disable */
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TrackingDataDto} from 'projects/dra-lib/src/lib/tracking/api/dto/tracking-data.dto';
import {Observable, Subscription} from 'rxjs';
import {BatchTransitionType} from '../models/batch-transition-type';
import {PieceInspectorRecentBatchesParams} from '../models/piece-inspector-recent-batches-params';
import {PieceSearchModel} from '../models/piece-search.model';
import {BatchComparisonDetailParams} from './../models/batch-comparison-detail-params';
import {BatchInspectorSummaryParams} from './../models/batch-inspector-summary-params';
import {ConfigurationApiService} from './configuration-api.service';
import {BatchFileDataDto} from './dto/batch-file-data.dto';
import {BatchInspectorCreateBatchParamsDto} from './dto/batch-inspector-create-batch-params.dto';
import {BatchInspectorSummaryParamsDto} from './dto/batch-inspector-summary-params.dto';
import {ClearanceInspectorCloseoutReturnDto} from './dto/clearance-inspector-closeout-return.dto';
import {ClearanceInspectorDataDto} from './dto/clearance-inspector-data.dto';
import {EmailFormDto} from './dto/email-form.dto';
import {FileSearchParamsDto} from './dto/file-search-params-dto';
import {GameCreationParamsDto} from './dto/game-creation-params.dto';
import {GatewayBatchDto} from './dto/gateway-batch.dto';
import {GatewayBatchesDto} from './dto/gateway-batches.dto';
import {GatewaySummaryParamsDto} from './dto/gateway-summary-params.dto';
import {PieceDetailParamsDto} from './dto/piece-detail-params.dto';
import {PieceDetailsPageDto} from './dto/piece-detail.dto';
import {PieceInspectorBatchSearchParamsDto} from './dto/piece-inspector-batch-search-params.dto';
import {PieceInspectorBatchDto} from './dto/piece-inspector-batch.dto';
import {PieceInspectorBatchesDto} from './dto/piece-inspector-batches.dto';
import {PieceInspectorNewBatchParamsDto} from './dto/piece-inspector-new-batch-params.dto';
import {PieceInspectorPieceParamsDto} from './dto/piece-inspector-piece-params.dto';
import {PieceInspectorScanPieceDto} from './dto/piece-inspector-scan-piece.dto';
import {PieceRemoveDto} from './dto/piece-remove';
import {DownloadFilesParams} from '../models/download-files-params';

@Injectable({
  providedIn: 'root',
})
export class BatchApiService {
  subscriptions = new Subscription();

  constructor(private http: HttpClient, private configService: ConfigurationApiService) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public getAllBatches(params?: GatewaySummaryParamsDto): Observable<GatewayBatchesDto> {
    return this.http.get<GatewayBatchesDto>(`${this.configService.getEndpointUrl()}/Batches`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getBatches(batchNumber: string): Observable<GatewayBatchesDto> {
    return this.http.get<GatewayBatchesDto>(`${this.configService.getEndpointUrl()}/Batches`, {
      params: JSON.parse(JSON.stringify({batchNumber})),
    });
  }

  public getSingleBatch(batchNumber: string): Observable<GatewayBatchDto> {
    return this.http.get<GatewayBatchDto>(`${this.configService.getEndpointUrl()}/Batch/${batchNumber}`);
  }

  public getGatewayPieceDetails(companyId: number, params: PieceDetailParamsDto): Observable<PieceDetailsPageDto> {
    return this.http.get<PieceDetailsPageDto>(`${this.configService.getEndpointUrl()}/BatchPieces`, {
      params: JSON.parse(JSON.stringify({...params, companyId})),
    });
  }

  public updateBatchStatus(
    batchNumbers: string[],
    transitionType: BatchTransitionType,
    appId?: string
  ): Observable<any> {
    return this.http.put(`${this.configService.getEndpointUrl()}/BatchStatus`, {
      BatchNumbers: batchNumbers,
      TransitionType: transitionType,
      AppID: appId || null,
    });
  }

  public updateBatchPieces(batchNumber: string, pieceIds: number[]): Observable<any> {
    return this.http.put(`${this.configService.getEndpointUrl()}/Batch`, {
      BatchNumber: batchNumber,
      Pieces: pieceIds.map(pieceID => ({PieceID: pieceID})),
    });
  }

  public createBatch(companyId: string, shipperId: string, pieceIds: {PieceID: number; Pin: string}[] = null) {
    return this.http.post(`${this.configService.getEndpointUrl()}/Batch`, {
      BatchType: 1005,
      CompanyID: companyId,
      Origin: shipperId,
      Pieces: pieceIds,
    });
  }

  public addPiecesToBatch(batchNumber: string, pieceIds: number[]): Observable<any> {
    return this.http.post(`${this.configService.getEndpointUrl()}/Batch/${batchNumber}`, pieceIds);
  }

  public removePiecesFromBatch(batchNumber: string, pieceIds: number[]): Observable<any> {
    return this.http.request('DELETE', `${this.configService.getEndpointUrl()}/Batch/${batchNumber}`, {body: pieceIds});
  }

  public removePiecesFromMultipleBatches(piecesDto: PieceRemoveDto[]): Observable<any> {
    return this.http.request('DELETE', `${this.configService.getEndpointUrl()}/Batches`, {body: piecesDto});
  }

  public searchPieceIds(companyId: string, shipperId: string, query: string): Observable<PieceSearchModel[]> {
    return this.http.get<PieceSearchModel[]>(`${this.configService.getEndpointUrl()}/PieceSearch`, {
      params: {
        CompanyID: companyId,
        Origin: shipperId,
        maxResults: '10',
        pinSearch: query,
      },
    });
  }

  public getFiles(params?: FileSearchParamsDto): Observable<BatchFileDataDto> {
    return this.http.get<BatchFileDataDto>(`${this.configService.getEndpointUrl()}/Documents`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public downloadFile(url?: string) {
    return this.http.get(url, {
      responseType: 'blob',
    });
  }

  public downloadMultipleFiles(params: DownloadFilesParams): Observable<any> {
    return this.http.get(`${this.configService.getEndpointUrl()}/DocumentTransfer`, {
      params: {...params},
      responseType: 'blob',
      observe: 'response',
    });
  }

  public shareFiles(params: EmailFormDto): Observable<any> {
    return this.http.post(`${this.configService.getEndpointUrl()}/DocumentEmail`, params);
  }

  public getAllBatchInspectorBatches(params: BatchInspectorSummaryParams): Observable<any> {
    return this.http.get<BatchInspectorSummaryParamsDto>(`${this.configService.getEndpointUrl()}/BatchInspectR`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public createBatchInspectorBatch(params: BatchInspectorCreateBatchParamsDto): Observable<any> {
    return this.http.post<BatchInspectorCreateBatchParamsDto>(`${this.configService.getEndpointUrl()}/Batches`, {
      ...params,
    });
  }

  public getComparisonBatches(batchNumbers: string): Observable<any> {
    return this.http.get<string>(`${this.configService.getEndpointUrl()}/VennDiagram`, {
      params: JSON.parse(JSON.stringify({batchNumbers})),
    });
  }

  public getComparisonBatchesDetail(params: BatchComparisonDetailParams): Observable<any> {
    return this.http.get<BatchComparisonDetailParams>(`${this.configService.getEndpointUrl()}/VennDiagramDetails`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public createComparisonBatch(params: BatchInspectorCreateBatchParamsDto): Observable<any> {
    return this.http.post<BatchInspectorCreateBatchParamsDto>(
      `${this.configService.getEndpointUrl()}/VennDiagramDetails`,
      {
        ...params,
      }
    );
  }

  public createComparisonDetailBatch(params: BatchInspectorCreateBatchParamsDto) {
    return this.http.post(`${this.configService.getEndpointUrl()}/Batch`, {
      ...params,
    });
  }

  public downloadComparisonFile() {
    return this.http.get(`${this.configService.getEndpointUrl()}/VennDiagramDownload`, {
      responseType: 'blob',
    });
  }

  public downloadComparisonBatchesDetail(params: BatchComparisonDetailParams) {
    return this.http.get(`${this.configService.getEndpointUrl()}/VennDiagramDownload`, {
      params: JSON.parse(JSON.stringify({...params})),
      responseType: 'blob',
    });
  }

  public getPieceInspectorBatches(params?: PieceInspectorBatchSearchParamsDto): Observable<PieceInspectorBatchesDto> {
    return this.http.get<PieceInspectorBatchesDto>(`${this.configService.getEndpointUrl()}/ScanBatches`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getPieceInspectorRecentBatches(
    params: PieceInspectorRecentBatchesParams
  ): Observable<PieceInspectorBatchesDto> {
    return this.http.get<PieceInspectorBatchesDto>(`${this.configService.getEndpointUrl()}/ScanBatches`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getPieceInspectorBatchFromScan(barCode: string, facilityCode: string): Observable<PieceInspectorBatchesDto> {
    return this.http.get<PieceInspectorBatchesDto>(`${this.configService.getEndpointUrl()}/ScanBatches`, {
      params: {
        barCode,
        facilityCode,
      },
    });
  }

  public getPieceInspectorBatch(batchId: string): Observable<PieceInspectorBatchDto> {
    return this.http.get<PieceInspectorBatchDto>(`${this.configService.getEndpointUrl()}/ScanBatch/${batchId}`);
  }

  public scanPieceInspectorPiece(params: PieceInspectorPieceParamsDto): Observable<PieceInspectorScanPieceDto> {
    return this.http.post<PieceInspectorScanPieceDto>(`${this.configService.getEndpointUrl()}/ScanPiece`, {
      ...params,
    });
  }

  public pieceInspectorNewBatch(params: PieceInspectorNewBatchParamsDto): Observable<PieceInspectorBatchDto> {
    return this.http.post<PieceInspectorBatchDto>(`${this.configService.getEndpointUrl()}/ScanBatch`, {
      ...params,
    });
  }

  public getPieceInspectorCloseoutBatches(
    params?: PieceInspectorBatchSearchParamsDto
  ): Observable<PieceInspectorBatchesDto> {
    return this.http.get<PieceInspectorBatchesDto>(`${this.configService.getEndpointUrl()}/ScanBatches`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getClearanceInspectorPieces(pieceIds: string[]): Observable<ClearanceInspectorDataDto> {
    return this.http.post<ClearanceInspectorDataDto>(`${this.configService.getEndpointUrl()}/Clearance`, {
      PinList: pieceIds,
    });
  }

  public downloadClearnaceInspectorSpreadsheet(pieceIds: string[]): Observable<any> {
    const payload = {PinList: pieceIds};
    return this.http.post(`${this.configService.getEndpointUrl()}/ClearanceDownload`, payload, {
      responseType: 'blob',
      // observe: 'response'
    });
  }

  public clearanceInspectorCloseout(pieceIds: string[]): Observable<ClearanceInspectorCloseoutReturnDto> {
    return this.http.post<ClearanceInspectorCloseoutReturnDto>(`${this.configService.getEndpointUrl()}/Clearance`, {
      Action: 'Closeout',
      PinList: pieceIds,
    });
  }

  //GAME

  public getPieceInspectorRecentGames(): Observable<PieceInspectorBatchesDto> {
    return this.http.get<PieceInspectorBatchesDto>(
      `${this.configService.getEndpointUrl()}/GameBatches?showRecent=true`
    );
  }

  public getPieceInspectorGameTemplates(
    params?: PieceInspectorBatchSearchParamsDto
  ): Observable<PieceInspectorBatchesDto> {
    return this.http.get<PieceInspectorBatchesDto>(`${this.configService.getEndpointUrl()}/GameBatches`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getPieceInspectorGameDetails(batchId: string): Observable<PieceInspectorBatchDto> {
    return this.http.get<PieceInspectorBatchDto>(`${this.configService.getEndpointUrl()}/GameBatch/${batchId}`);
  }

  public createGameFromTemplate(params: GameCreationParamsDto): Observable<PieceInspectorBatchDto> {
    return this.http.post<PieceInspectorBatchDto>(`${this.configService.getEndpointUrl()}/GameBatch`, {
      ...params,
    });
  }

  public updateGame(status: string, batchNumbers: string[]) {
    return this.http.put(`${this.configService.getEndpointUrl()}/GameStatus`, {
      TransitionType: status[0].toUpperCase() + status.slice(1),
      BatchNumbers: batchNumbers,
    });
  }

  public scanPieceInspectorGamePiece(params: PieceInspectorPieceParamsDto): Observable<PieceInspectorScanPieceDto> {
    return this.http.post<PieceInspectorScanPieceDto>(`${this.configService.getEndpointUrl()}/GamePiece`, {
      ...params,
    });
  }

  public getAuthTrackingData(searchValue: string, timeZone: string): Observable<TrackingDataDto> {
    return this.http.post<TrackingDataDto>(`${this.configService.getEndpointUrl()}/TrackingAuth`, {
      TrackingNumber: searchValue,
      RequestedTimeZone: timeZone,
    });
  }
}
