import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, mergeMap} from 'rxjs/operators';
import {exportEventRecordrBatchParamsModelToDto} from 'src/app/event-recordr/shared/converters/convert-event-recordr-batch-params-model-to-dto';
import {EventRecordrApiService} from '../../api/event-recordr-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  CloseoutEventRecordrBatchAction,
  CloseoutEventRecordrBatchActionSuccess,
  EventRecordrActionType,
  GetEventRecordrBatchAction,
  GetEventRecordrBatchSuccessAction,
  GetEventRecordrBatchesAction,
  GetEventRecordrBatchesSuccessAction,
  GetEventRecordrPiecesAction,
  GetEventRecordrPiecesSuccessAction,
  GetEventRecordrShipmentManagerBatchAction,
  GetEventRecordrShipmentManagerBatchSuccessAction,
  GetEventRecordrShipmentManagerBatchesAction,
  GetEventRecordrShipmentManagerBatchesSuccessAction,
} from './event-recordr.action';

@Injectable()
export class EventRecordrEffects {
  public getBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetEventRecordrBatchesAction>(EventRecordrActionType.GET_BATCHES),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = exportEventRecordrBatchParamsModelToDto(params);

        return this.eventRecordrApiService.getBatches(paramsDto).pipe(
          // map(dto => ({
          //   recentBatches: dto?.BatchList?.map(batch => convertClientDashboardBatchDtoToModel(batch)) || [],
          //   recentBatchesCount: dto?.RecordCount,
          // })),
          mergeMap(batches => [
            new GetEventRecordrBatchesSuccessAction({batches, getMore: params.loadMore}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetEventRecordrBatchAction>(EventRecordrActionType.GET_BATCH),
      mergeMap(action => {
        const {batchId, onSuccess, onFailure} = action.payload;

        return this.eventRecordrApiService.getBatch(batchId).pipe(
          mergeMap(batch => [new GetEventRecordrBatchSuccessAction({batch}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getPieces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetEventRecordrPiecesAction>(EventRecordrActionType.GET_PIECES),
      mergeMap(action => {
        const {batchId, onSuccess, onFailure} = action.payload;

        return this.eventRecordrApiService.getPieces(batchId).pipe(
          mergeMap(pieces => [new GetEventRecordrPiecesSuccessAction({pieces}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getShipmentManagerBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetEventRecordrShipmentManagerBatchesAction>(EventRecordrActionType.GET_SHIPMENT_MANAGER_BATCHES),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.eventRecordrApiService.getShipmentManagerBatches().pipe(
          mergeMap(batches => [
            new GetEventRecordrShipmentManagerBatchesSuccessAction({batches}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getShipmentMangerBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetEventRecordrShipmentManagerBatchAction>(EventRecordrActionType.GET_SHIPMENT_MANAGER_BATCH),
      mergeMap(action => {
        const {batchId, onSuccess, onFailure} = action.payload;

        return this.eventRecordrApiService.getShipmentManagerBatch(batchId).pipe(
          mergeMap(batch => [
            new GetEventRecordrShipmentManagerBatchSuccessAction({batch}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public closeoutEventRecordrBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CloseoutEventRecordrBatchAction>(EventRecordrActionType.CLOSEOUT_EVENT_RECORDR_BATCH),
      mergeMap(action => {
        const {batchIdentifier, onSuccess, onFailure} = action.payload;

        return this.eventRecordrApiService.closeoutEventRecordrBatch(batchIdentifier).pipe(
          mergeMap(result => [
            new CloseoutEventRecordrBatchActionSuccess({result}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(private actions$: Actions, private eventRecordrApiService: EventRecordrApiService) {}
}
