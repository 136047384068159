import {
  AccountGroup,
  Application,
  ApplicationId,
  Company,
  LocationMatrix,
  LocationMatrixCompany,
  LocationMatrixLocationRecord,
  OriginCountry,
  OriginLocation,
  PuroPostInduction,
  SearchFilter,
  SearchFilterGroup,
  SearchOption,
  SearchOrigin,
} from '../../models/application';
import {
  AccountGroupDto,
  ApplicationDto,
  CompanyDto,
  LocationMatrixCompanyDto,
  LocationMatrixDto,
  LocationMatrixLocationRecordDto,
  OriginCountryDto,
  OriginLocationDto,
  PuroPostInductionDto,
  SearchFilterDto,
  SearchFilterGroupDto,
  SearchOptionDto,
  SearchOriginDto,
} from '../dto/application.dto';

export function convertApplicationDtoToModel(dto: ApplicationDto): Application {
  return {
    appGuid: dto.AppGuid,
    id: dto.AppID as ApplicationId,
    caption: dto.Caption,
    description: dto.Description,
    companies: dto.Companies?.map(company => convertCompanyDtoToModel(company)) || [],
    clearanceStatusTypes: dto.ClearanceStatusTypes ? convertSearchOptionDtosToModels(dto.ClearanceStatusTypes) : [],
    clearanceTypes: convertSearchOptionDtosToModels(dto.ClearanceTypes),
    pieceTypes: convertSearchOptionDtosToModels(dto.PieceTypes),
    statusTypes: convertSearchOptionDtosToModels(dto.StatusTypes),
    batchOperations: dto.BatchOperations,
    locationMatrix: dto.LocationMatrix && convertLocationMatrixDtoToModel(dto.LocationMatrix),
    showCloseOutByContainer: dto.ShowCloseOutByContainer || null,
    showCloseOutByPieces: dto.ShowCloseOutByPieces || null,
    showCreateNewContainer: dto.ShowCreateNewContainer || null,
    showViewContainers: dto.ShowViewContainers || null,
    puroPostInductions: convertPuroPostInductionDtoToModel(dto.PuroPostInductions),
    transitStatusTypes: dto.TransitStatusTypes ? convertSearchOptionDtosToModels(dto.TransitStatusTypes) : [],
    showConsigneeInformation: dto.ShowConsigneeInformation || null,
  };
}

function convertLocationMatrixDtoToModel(dto: LocationMatrixDto): LocationMatrix {
  return {
    companies: dto.Companies?.map(company => convertLocationMatrixCompanyDtoToModel(company)),
    locationRecords: dto.LocationRecords?.map(record => convertLocationMatrixLocationRecordDtoToModel(record)),
  };
}

function convertLocationMatrixLocationRecordDtoToModel(
  dto: LocationMatrixLocationRecordDto
): LocationMatrixLocationRecord {
  return {
    accountGroup: dto.AccountGroup,
    companyId: dto.CompanyID,
    inductionLocation: dto.InductionLocation,
    originCountry: dto.OriginCountry,
    originId: dto.OriginID,
    originLocation: dto.OriginLocation,
  };
}

function convertLocationMatrixCompanyDtoToModel(dto: LocationMatrixCompanyDto): LocationMatrixCompany {
  return {
    accountGroups: dto.AccountGroups?.map(group => convertAccountGroupsDtoToModel(group)),
    companyId: dto.CompanyID,
  };
}

function convertAccountGroupsDtoToModel(dto: AccountGroupDto): AccountGroup {
  return {
    groupName: dto.GroupName,
    originCountries: dto.OriginCountries?.map(country => convertOriginCountryDtoToModel(country)),
  };
}

function convertOriginCountryDtoToModel(dto: OriginCountryDto): OriginCountry {
  return {
    countryCode: dto.CountryCode,
    originLocations: dto.OriginLocations?.map(location => convertOriginLocationDtoToModel(location)),
  };
}

function convertOriginLocationDtoToModel(dto: OriginLocationDto): OriginLocation {
  return {
    inductionLocations: dto.InductionLocations,
    locationCode: dto.LocationCode,
  };
}

function convertCompanyDtoToModel(dto: CompanyDto): Company {
  return {
    id: dto.CompanyID,
    description: dto.Description,
    facilities: convertSearchOptionDtosToModels(dto.Facilities),
    origins: dto.Origins?.map(origin => convertSearchOriginDtoToModel(origin)) || [],
    filterGroups: dto.FilterGroups?.map(group => convertSearchFilterGroupDtoToModel(group)) || [],
    filters: dto.Filters?.map(filter => convertSearchFilterDtoToModel(filter)) || [],
  };
}

function convertSearchOptionDtosToModels(dtos: SearchOptionDto[]): SearchOption[] {
  return dtos?.map(dto => convertSearchOptionDtoToModel(dto)) || [];
}

function convertSearchOptionDtoToModel(dto: SearchOptionDto): SearchOption {
  return {
    code: dto.Code !== 'null' ? dto.Code : '',
    description: dto.Description,
  };
}

function convertSearchOriginDtoToModel(dto: SearchOriginDto): SearchOrigin {
  return {
    code: dto.Code !== 'null' ? dto.Code : '',
    description: dto.Description,
    facilityCode: dto.FacilityCode,
  };
}

function convertSearchFilterGroupDtoToModel(dto: SearchFilterGroupDto): SearchFilterGroup {
  return {
    code: dto.Code,
    description: dto.Description,
  };
}

function convertSearchFilterDtoToModel(dto: SearchFilterDto): SearchFilter {
  return {
    clearanceStatusType: [0, 1].includes(dto?.ClearanceStatusType) ? Boolean(dto?.ClearanceStatusType) : null,
    groupID: dto.GroupID,
    id: dto.ID,
    label: dto.Label,
    labelGroup: dto.LabelGroup,
    sequence: dto.Sequence,
    statusType: dto.StatusType,
  };
}

function convertPuroPostInductionDtoToModel(dtos: PuroPostInductionDto[]): PuroPostInduction[] {
  return dtos?.map(dto => convertSearchOptionDtoToModel(dto)) || [];
}
