import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError, mergeMap, take} from 'rxjs/operators';
import {createAuthHeaders} from '../../../shared/utils/auth/create-auth-headers';
import {selectAuthToken} from '../../store/auth/auth.selector';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private store$: Store<{}>) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.store$.pipe(
      select(selectAuthToken),
      take(1),
      mergeMap(authToken => {
        const authRequest = request.clone({
          setHeaders: createAuthHeaders(authToken),
        });

        return next.handle(authRequest).pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401 && !this.router.url.startsWith('/login')) {
              this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}});
              return EMPTY;
            }
            return throwError(error);
          })
        );
      })
    );
  }
}
