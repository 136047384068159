import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {convertPieceDtoToModel} from '../api/converter/convert-piece-dto-to-model';
import {TrackingApiService} from '../api/tracking-api.service';
import {
  GetPiecesAction,
  GetPiecesErrorAction,
  GetPiecesSuccessAction,
  TrackingActionType,
  UpdatePiecesLoadingAction,
} from './tracking.action';
import {Store} from '@ngrx/store';

@Injectable()
export class TrackingEffects {
  public getTrackingData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetPiecesAction>(TrackingActionType.GET_PIECES),
      mergeMap(action => {
        const {searchValue, timeZone} = action.payload;
        this.store$.dispatch(new UpdatePiecesLoadingAction({piecesLoading: true}));
        return this.trackingApiService.getTrackingData(searchValue, timeZone).pipe(
          map(data => data?.Pieces?.map(dto => convertPieceDtoToModel(dto)) || []),
          mergeMap(pieces => [
            new GetPiecesSuccessAction({pieces, searchValue}),
            new UpdatePiecesLoadingAction({piecesLoading: false}),
            new GetPiecesErrorAction({error: null}),
          ]),
          catchError(error => {
            this.store$.dispatch(new UpdatePiecesLoadingAction({piecesLoading: false}));
            return of(new GetPiecesErrorAction({error}));
          })
        );
      })
    )
  );

  constructor(private store$: Store, private actions$: Actions, private trackingApiService: TrackingApiService) {}
}
