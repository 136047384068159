import {ManifestrBatchDto} from '../dto/manifestr-batch.dto';
import {ManifestrBatch} from '../model/manifestr-batch';

export function convertManifestrBatchDtoToModel(dto: ManifestrBatchDto): ManifestrBatch {
  return {
    statusCategory: dto.StatusCategory,
    batchHeader: dto.BatchHeader,
    batchId: dto.BatchID,
    batchLabel: dto.BatchLabel,
    gatewayArrivalDate: dto.GatewayArrivalDate,
    depotCode: dto.DepotCode,
    expectedArrivalCanadaDisplay: dto.ExpectedArrivalCanadaDisplay,
    facilityCode: dto.FacilityCode,
    originLabel: dto.OriginLabel,
    totalPieces: dto.TotalPieces,
    totalWeightLbs: dto.TotalWeightLbs,
    totalWeightLbsDisplay: dto.TotalWeightLbsDisplay,
    totalWeightKgs: dto.TotalWeightKg,
    totalWeightKgDisplay: dto.TotalWeightKgDisplay,
    shipDate: dto.ShipDate,
    manifestrQueueAdded: dto.ManifestRQueueAdded,
    manifestrQueueDateAdded: dto.ManifestRQueueDateAdded,
  };
}
