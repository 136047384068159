import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {DraTimezoneDialogComponent} from './dialog/dra-timezone-dialog.component';

@Component({
  selector: 'dra-timezone-selector',
  templateUrl: './dra-timezone-selector.component.html',
  styleUrls: ['./dra-timezone-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraTimezoneSelectorComponent {
  @Input() selectedTimeZone: string;

  @Output() valueChange = new EventEmitter<string>();

  selectedTimeZoneDisplay: string;
  readonly timeZoneOptions = [
    {
      value: 'User',
      display: ['New', 'York', 'Time'],
      subtext: 'Time at Eastern US',
      iconSrc: 'assets/icons/timezone-selector/liberty.svg',
    },
    {
      value: 'Local',
      display: ['Local', 'Time'],
      subtext: 'Time at place scanned',
      iconSrc: 'assets/icons/timezone-selector/pin.svg',
    },
    {
      value: 'Origin',
      display: ['Origin', 'Time'],
      subtext: 'Time at place sent from',
      iconSrc: 'assets/icons/timezone-selector/plane.svg',
    },
    {
      value: 'Destination',
      display: ['Destination', 'Time'],
      subtext: 'Time at place headed to',
      iconSrc: 'assets/icons/timezone-selector/home.svg',
    },
  ];

  constructor(private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedTimeZone && this.selectedTimeZone) {
      this.selectedTimeZoneDisplay =
        this.timeZoneOptions.find(option => option.value === this.selectedTimeZone)?.display?.join(' ') || '';
    }
  }

  onClick() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'timezone-selector-dialog';
    dialogConfig.data = {selectedTimezone: this.selectedTimeZone, timeZoneOptions: this.timeZoneOptions};
    const dialog = this.dialog.open(DraTimezoneDialogComponent, dialogConfig);
    dialog.afterClosed().subscribe(value => {
      if (value) {
        this.valueChange.emit(value);
      }
    });
  }
}
