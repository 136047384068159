import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {EventRecordrBatchParamsDto} from 'src/app/event-recordr/shared/dto/event-recordr-batch-params.dto';
import {EventRecordrBatch} from 'src/app/event-recordr/shared/model/event-recordr-batch';
import {EventRecordrShipmentManagerBatch} from 'src/app/event-recordr/shared/model/event-recrodr-shipment-manager-batch';
import {SHIPMENT_MANAGER_MOCK_DATA} from 'src/app/event-recordr/shipment-manager/shared/shipment-manager-mock-data';
import {eventRecordrApiUrl} from 'src/environments/environment';
import {Configuration} from '../models/configuration';
import {selectConfiguration} from '../store/workspace/workspace.selector';
import {EventRecordrPiece} from 'src/app/event-recordr/shared/model/event-recordr-piece';
import {ApplicationId} from '../models/application';
import {BatchTransitionType} from '../models/batch-transition-type';

@Injectable({
  providedIn: 'root',
})
export class EventRecordrApiService {
  private configuration$: Observable<Configuration>;
  private url: string;
  private appUrl: string;

  constructor(private http: HttpClient, private store$: Store) {
    this.configuration$ = this.store$.pipe(select(selectConfiguration));
    this.configuration$
      .pipe(
        filter(config => !!config),
        take(1)
      )
      .subscribe(config => {
        config;
        this.appUrl = `${config.userInformation.endpointUrlDra}/api`;
        this.url = eventRecordrApiUrl;
        this.http;
      });
  }

  public getBatches(params: EventRecordrBatchParamsDto): Observable<EventRecordrBatch[]> {
    return this.http.get<EventRecordrBatch[]>(`${this.url}/batch`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
    // return of(MOCK_EVENT_RECORDR_BATCH_LIST);
  }

  public getBatch(batchId: string): Observable<EventRecordrBatch> {
    return this.http.get<EventRecordrBatch>(`${this.url}/batch/${batchId}`);
    // return of(MOCK_EVENT_RECORDR_BATCH_LIST);
  }

  public getPieces(batchId: string): Observable<EventRecordrPiece[]> {
    return this.http.get<EventRecordrPiece[]>(`${this.url}/batch/${batchId}/pieces`);
  }

  public getShipmentManagerBatches(): Observable<EventRecordrShipmentManagerBatch[]> {
    // return this.http.get<EventRecordrBatch[]>(`${this.url}/batch`);
    return of(SHIPMENT_MANAGER_MOCK_DATA);
  }

  public getShipmentManagerBatch(batchId: string): Observable<EventRecordrShipmentManagerBatch> {
    batchId;
    // return this.http.get<EventRecordrBatch>(`${this.url}/batch/123`);
    return of(SHIPMENT_MANAGER_MOCK_DATA[0]);
  }

  public closeoutEventRecordrBatch(batchIdentifier: string): Observable<unknown> {
    return this.http.put(`${this.appUrl}/BatchStatus`, {
      BatchIdentifier: batchIdentifier,
      TransitionType: BatchTransitionType.Closeout,
      ExcludePieceIDs: [],
      AppID: ApplicationId.EventRecordR,
    });
  }
}
