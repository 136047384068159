import {ManifestrManifestEvent} from './model/manifestr-manifest-group';

export const MANIFESTR_MOCK_MANIFEST_GROUP: ManifestrManifestEvent = {
  document: null,
  documentUrl: null,
  groupCaption: '2023 May 15 6:01 PM',
  groupId: 31985999,
  manifestsGenerated: false,
  sort: 'CPC Sort: Toronto',
  totalCount: 13,
  totalWeightKgDisplay: '29.12 kg',
  totalWeightLbsDisplay: '64.20 lb',
  manifests: getManifests(),
};

function getManifests() {
  const objects = [];

  for (let i = 11; i <= 20; i++) {
    const obj = {
      account: null,
      count: 13 + i,
      date: `2023-05-1${i}T18:01:44`,
      document: {
        activity: 'Clearance',
        activityId: i + 1,
        batchNumbers: undefined,
        creationDate: `2023-05-1${i - 3}T18:01:54.28`,
        creationDateText: null,
        depotCode: null,
        description: 'CPCSummaryManifest',
        documentId: 31986003 + i - 10,
        generatedBy: 'OLS3',
        iconType: 1,
        itemCount: 0,
        manifestNumber: null,
        masterBOL: null,
        olsBatchNumber: `ManifestR-31985999-I029-40089${i}*`,
        olsCloseoutURL: null,
        olsSite: null,
        orderCount: 0,
        origin: 0,
        originLabel: null,
        packageCount: 0,
        parsNumber: null,
        title: `F021276990000${i}`,
        type: 'CPCSummaryManifest',
        typeId: 105,
        url: '',
      },
      documentId: i,
      documentUrl: null,
      name: null,
      totalWeightKgDisplay: `${29 + i / 100} kg`,
      totalWeightLbsDisplay: `${64 + i / 100} lb`,
    };

    objects.push(obj);
  }
  return objects;
}
