import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {convertManifestrBatchDtoToModel} from 'src/app/manifestr/shared/converters/convert-manifestr-batch-dto-to-model';
import {convertManifestrQueueCompanyListDtoToModel} from 'src/app/manifestr/shared/converters/convert-manifestr-company-list-dto-to-model';
import {convertManifestrDestinationListDtoToModel} from 'src/app/manifestr/shared/converters/convert-manifestr-destination-list-dto-to-model';
import {convertManifestrDispatchLocationsDtoToModel} from 'src/app/manifestr/shared/converters/convert-manifestr-dispatch-location-dto-to-model';
import {convertManifestrManifestGroupDtoToModel} from 'src/app/manifestr/shared/converters/convert-manifestr-manifest-group-dto-to-model';
import {convertManifestrManifestListDtoToModel} from 'src/app/manifestr/shared/converters/convert-manifestr-manifest-list-dto-to-model';
import {convertManifestrDispatchParamsModelToDto} from 'src/app/manifestr/shared/converters/convert-manifestr-search-params-model-to-dto';
import {convertManifestrEditQueueSearchParamsModelToDto} from 'src/app/manifestr/shared/converters/convert-manifestr-select-batches-search-params-model-to-dto';
import {ManifestrApiService} from '../../api/manifestr-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  GenereateManifestrManifestAction,
  GenereateManifestrManifestSuccessAction,
  GetManifestrBatchesAction,
  GetManifestrBatchesSuccessAction,
  GetManifestrDispatchGatewaysAction,
  GetManifestrDispatchGatewaysSuccessAction,
  GetManifestrManifestsAction,
  GetManifestrManifestsSuccessAction,
  GetManifestrQueueByCompanySuccessAction,
  GetManifestrQueueByDestinationAction,
  GetManifestrQueueByDestinationSuccessAction,
  GetSingleManifestrManifestAction,
  GetSingleManifestrManifestSuccessAction,
  ManifestrActionType,
  RemoveManifestrBatchAction,
  RemoveManifestrBatchSuccessAction,
  SelectManifestrBatchesAction,
  SelectManifestrBatchesSuccessAction,
} from './manifestr.actions';

@Injectable()
export class ManifestrEffects {
  public getDispatchLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetManifestrDispatchGatewaysAction>(ManifestrActionType.GET_DISPATCH_GATEWAYS),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertManifestrDispatchParamsModelToDto(params);

        return this.manifestrApiService.getDispatchLocations(paramsDto).pipe(
          map(dto => convertManifestrDispatchLocationsDtoToModel(dto) || []),
          mergeMap(diaptchLocations => [
            new GetManifestrDispatchGatewaysSuccessAction({
              dispatchLocations: diaptchLocations,
            }),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetManifestrBatchesAction>(ManifestrActionType.GET_BATCHES),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertManifestrDispatchParamsModelToDto(params);
        return this.manifestrApiService.getBatches(paramsDto).pipe(
          map(dto => dto?.BatchList.map(batch => convertManifestrBatchDtoToModel(batch))),
          mergeMap(batches => [
            new GetManifestrBatchesSuccessAction({batches, params}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public selectBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SelectManifestrBatchesAction>(ManifestrActionType.SELECT_BATCHES),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertManifestrEditQueueSearchParamsModelToDto(params);

        return this.manifestrApiService.selectBatches(paramsDto).pipe(
          map(dto => convertManifestrManifestListDtoToModel(dto)),
          mergeMap(manifestList => [
            new SelectManifestrBatchesSuccessAction({manifestList: manifestList.destinations}),
            ...createCallbackActions(onSuccess, manifestList.destinations),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public removeBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveManifestrBatchAction>(ManifestrActionType.REMOVE_BATCH),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertManifestrEditQueueSearchParamsModelToDto(params);

        return this.manifestrApiService.removeBatches(paramsDto).pipe(
          mergeMap(() => [new RemoveManifestrBatchSuccessAction({}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getQueueByDestination$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetManifestrQueueByDestinationAction>(ManifestrActionType.GET_QUEUE_BY_DESTINATION),
      mergeMap(action => {
        const {inductionDepotCode, onSuccess, onFailure} = action.payload;

        return this.manifestrApiService.getQueueByDestination(inductionDepotCode).pipe(
          map(dto => convertManifestrDestinationListDtoToModel(dto)),
          mergeMap(destinationList => [
            new GetManifestrQueueByDestinationSuccessAction({destinationList: destinationList.destinations}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getQueueByCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetManifestrQueueByDestinationAction>(ManifestrActionType.GET_QUEUE_BY_COMPANY),
      mergeMap(action => {
        const {inductionDepotCode, onSuccess, onFailure} = action.payload;

        return this.manifestrApiService.getQueueByCompany(inductionDepotCode).pipe(
          map(dto => convertManifestrQueueCompanyListDtoToModel(dto)),
          mergeMap(queueCompanyList => [
            new GetManifestrQueueByCompanySuccessAction({queueCompanyList}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getManifests$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetManifestrManifestsAction>(ManifestrActionType.GET_MANIFESTS),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertManifestrDispatchParamsModelToDto(params);

        return this.manifestrApiService.getManifests(paramsDto).pipe(
          map(dto => convertManifestrManifestGroupDtoToModel(dto)),
          mergeMap(manifestGroup => [
            new GetManifestrManifestsSuccessAction({
              manifestGroup,
            }),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getSingleManifest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSingleManifestrManifestAction>(ManifestrActionType.GET_SINGLE_MANIFEST),
      mergeMap(action => {
        const {groupId, onSuccess, onFailure} = action.payload;

        return this.manifestrApiService.getSingleManifest(groupId).pipe(
          // map(dto => convertManifestrManifestingEventsDtoToModel(dto)),
          mergeMap(manifestEvent => [
            new GetSingleManifestrManifestSuccessAction({
              manifestEvent,
            }),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public generateManifest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GenereateManifestrManifestAction>(ManifestrActionType.GENERATE_MANIFEST),
      mergeMap(action => {
        const {inductionDepotCode, onSuccess, onFailure} = action.payload;

        return this.manifestrApiService.generateManifest(inductionDepotCode).pipe(
          map(dto => convertManifestrManifestGroupDtoToModel(dto)),
          mergeMap(manifestGroup => [
            new GenereateManifestrManifestSuccessAction({manifestGroup}),
            ...createCallbackActions(onSuccess, manifestGroup),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(private actions$: Actions, private manifestrApiService: ManifestrApiService) {}
}
