import {BatchManagerFilterStatus} from './../core/models/batch-manager-filter-status';
import {Injectable} from '@angular/core';
import {ApplicationId} from '../core/models/application';
import {GatewayFilterStatus} from '../core/models/gateway-filter-status';
import {MenuItems} from '../core/models/menu-items';
import {MenuItemsTracking} from '../core/models/menu-items-tracking';
import {MobileMenuItemsTracking} from '../core/models/mobile-menu-items-tracking';
import {QuickAccessItems} from '../core/models/quick-access-items';

/* Define app-level constants which can be imported to any module */
@Injectable()
export class Constants {
  public readonly MENU_ITEMS: MenuItems[] = [
    {
      name: 'Home',
      address: '/',
      activeIcon: 'assets/home-v-1.svg',
      inactiveIcon: 'assets/home-v-1-inactive.svg',
    },
    {
      name: 'PARS',
      address: '/pars',
      activeIcon: 'assets/pars-v-1.svg',
      inactiveIcon: 'assets/pars-v-1-inactive.svg',
    },
    {
      name: 'Facilities',
      address: '/facility',
      activeIcon: 'assets/facilities-v-1.svg',
      inactiveIcon: 'assets/facilities-v-1-inactive.svg',
    },
    {
      name: 'Clients',
      address: '/client',
      activeIcon: 'assets/clients-v-1.svg',
      inactiveIcon: 'assets/clients-v-1-inactive.svg',
    },
    {
      name: 'Legs',
      address: '/leg',
      activeIcon: 'assets/legs-v-1.svg',
      inactiveIcon: 'assets/legs-v-1-inactive.svg',
    },
  ];

  public readonly MENU_ITEMS_TRACKING: MenuItemsTracking[] = [
    {
      name: 'Home',
      address: '/home',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/home-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/home-resting.svg',
      tooltip: 'Home',
      mobileName: 'Home',
    },
    {
      applicationId: ApplicationId.BatchInspectR,
      name: 'Batch InspectR',
      address: '/batch-inspectr',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/batch-inspectr-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/batch-inspectr-resting.svg',
      tooltip: 'Batch InspectR',
      mobileName: 'Batch InspectR',
    },
    {
      applicationId: ApplicationId.BatchTracking,
      name: 'Batch Tracking',
      address: '/batch-tracking',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/batch-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/batch-resting.svg',
      tooltip: 'Batch Tracking',
      mobileName: 'Batches',
    },
    {
      applicationId: ApplicationId.ClearanceInspectR,
      name: 'Clearance InspectR',
      address: '/clearance-inspectr',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/clearance-inspectr-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/clearance-inspectr-resting.svg',
      tooltip: 'Clearance InspectR',
      mobileName: 'Clearance InspectR',
    },
    {
      applicationId: ApplicationId.Documents,
      name: 'Document Repository',
      address: '/file-search',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/files-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/files-resting.svg',
      tooltip: 'Document Repository',
      mobileName: 'Documents',
    },
    {
      applicationId: ApplicationId.GatewayBatchManager,
      name: 'Gateway Batch Manager',
      address: '/gateway',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/gbm-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/gbm-resting.svg',
      tooltip: 'Gateway Batch Manager',
      mobileName: 'GBM',
    },
    {
      applicationId: ApplicationId.Games,
      name: 'Gateway Games',
      address: '/piece-inspectr-game',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/gateway-games-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/gateway-games-resting.svg',
      tooltip: 'Gateway Games',
      mobileName: 'Gateway Games',
    },
    {
      // applicationId: ApplicationId.ManifestR,
      name: 'ManifestR',
      address: '/manifestr',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/manifestr-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/manifestr-resting.svg',
      tooltip: 'ManifestR',
      mobileName: 'ManifestR',
    },
    {
      applicationId: ApplicationId.PieceInspectR,
      name: 'Piece InspectR',
      address: '/piece-inspectr',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/piece-inspectr-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/piece-inspectr-resting.svg',
      tooltip: 'Piece InspectR',
      mobileName: 'Piece InspectR',
    },
    {
      applicationId: ApplicationId.PieceTracking,
      name: 'Piece Tracking',
      address: '/tracking',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/pieces-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/pieces-resting.svg',
      tooltip: 'Piece Tracking',
      mobileName: 'Tracking',
    },
    {
      applicationId: ApplicationId.ClientDashboard,
      name: 'Shipment ManageR',
      address: '/dashboard',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/dashboard-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/dashboard-resting.svg',
      tooltip: 'Shipment ManageR',
      mobileName: 'Shipment ManageR',
    },
    {
      applicationId: ApplicationId.EventRecordR,
      name: 'Event RecordR',
      address: '/event-recordr',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/event-recordr-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/event-recordr-resting.svg',
      tooltip: 'Event RecordR',
      mobileName: 'Event RecordR',
    },
    {
      applicationId: ApplicationId.ReturnR,
      name: 'ReturnR',
      address: '/returnr',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/returnr-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/returnr-resting.svg',
      tooltip: 'ReturnR',
      mobileName: 'ReturnR',
    },
  ];

  public readonly MOBILE_MENU_ITEMS_TRACKING: MobileMenuItemsTracking[] = [
    {
      name: 'Home',
      address: '/home',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/home-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/home-resting.svg',
      tooltip: 'Home',
      mobileName: 'Home',
    },
    {
      applicationId: ApplicationId.BatchInspectR,
      name: 'Batch InspectR',
      address: '/batch-inspectr',
      activeIcon: 'assets/icons/tracking-icons/mobile-navbar/batch-inspectr-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/mobile-navbar/batch-inspectr-resting.svg',
      tooltip: 'Batch InspectR',
      mobileName: 'Batch InspectR',
    },
    {
      applicationId: ApplicationId.BatchTracking,
      name: 'Batch Tracking',
      address: '/batch-tracking',
      activeIcon: 'assets/icons/tracking-icons/mobile-navbar/batch-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/mobile-navbar/batch-resting.svg',
      tooltip: 'Batch Tracking',
      mobileName: 'Batches',
    },
    {
      applicationId: ApplicationId.ClearanceInspectR,
      name: 'Clearance InspectR',
      address: '/clearance-inspectr',
      activeIcon: 'assets/icons/tracking-icons/mobile-navbar/clearance-inspectr-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/mobile-navbar/clearance-inspectr-resting.svg',
      tooltip: 'Clearance InspectR',
      mobileName: 'Clearance InspectR',
    },
    {
      applicationId: ApplicationId.Documents,
      name: 'Document Repository',
      address: '/file-search',
      activeIcon: 'assets/icons/tracking-icons/mobile-navbar/files-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/mobile-navbar/files-resting.svg',
      tooltip: 'Document Repository',
      mobileName: 'Documents',
    },
    {
      applicationId: ApplicationId.GatewayBatchManager,
      name: 'Gateway Batch Manager',
      address: '/gateway',
      activeIcon: 'assets/icons/tracking-icons/mobile-navbar/gbm-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/mobile-navbar/gbm-resting.svg',
      tooltip: 'Gateway Batch Manager',
      mobileName: 'GBM',
    },
    {
      applicationId: ApplicationId.Games,
      name: 'Gateway Games',
      address: '/piece-inspectr-game',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/gateway-games-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/gateway-games-resting.svg',
      tooltip: 'Gateway Games',
      mobileName: 'Gateway Games',
    },
    {
      applicationId: ApplicationId.ManifestR,
      name: 'ManifestR',
      address: '/manifestr',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/manifestr-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/manifestr-resting.svg',
      tooltip: 'ManifestR',
      mobileName: 'ManifestR',
    },
    {
      applicationId: ApplicationId.PieceInspectR,
      name: 'Piece InspectR',
      address: '/piece-inspectr',
      activeIcon: 'assets/icons/tracking-icons/mobile-navbar/piece-inspectr-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/mobile-navbar/piece-inspectr-resting.svg',
      tooltip: 'Piece InspectR',
      mobileName: 'Piece InspectR',
    },
    {
      applicationId: ApplicationId.PieceTracking,
      name: 'Piece Tracking',
      address: '/tracking',
      activeIcon: 'assets/icons/tracking-icons/mobile-navbar/pieces-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/mobile-navbar/pieces-resting.svg',
      tooltip: 'Piece Tracking',
      mobileName: 'Tracking',
    },
    {
      applicationId: ApplicationId.ClientDashboard,
      name: 'Shipment ManageR',
      address: '/dashboard',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/dashboard-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/dashboard-resting.svg',
      tooltip: 'Shipment ManageR',
      mobileName: 'Shipment ManageR',
    },
    {
      applicationId: ApplicationId.EventRecordR,
      name: 'Event RecordR',
      address: '/event-recordr',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/dashboard-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/dashboard-resting.svg',
      tooltip: 'Event RecordR',
      mobileName: 'Event RecordR',
    },
    {
      applicationId: ApplicationId.ReturnR,
      name: 'ReturnR',
      address: '/returnr',
      activeIcon: 'assets/icons/tracking-icons/tracking-navbar/returnr-active.svg',
      inactiveIcon: 'assets/icons/tracking-icons/tracking-navbar/returnr-resting.svg',
      tooltip: 'ReturnR',
      mobileName: 'ReturnR',
    },
  ];

  public readonly QUICK_ACCESS_ITEMS: QuickAccessItems[] = [
    {
      name: 'Get New PARS Number',
      address: '',
      tags: ['PARS'],
    },
    {
      name: 'Find Existing PARS Number',
      address: '',
      tags: ['PARS'],
    },
    {
      name: 'Add New Carrier',
      address: '',
      tags: ['Carriers'],
    },
    {
      name: 'Modify Existing Carrier',
      address: '',
      tags: ['Carriers'],
    },
    {
      name: 'Add New Client',
      address: '',
      tags: ['Clients'],
    },
    {
      name: 'Modify Existing Client',
      address: '',
      tags: ['Clients'],
    },
    {
      name: 'Change Carrier for a Client',
      address: '',
      tags: ['Carriers', 'Clients'],
    },
    {
      name: 'Change Port of Entry for a Client',
      address: '',
      tags: ['Clients', 'Facilities', 'Legs'],
    },
    {
      name: 'Change Carrier for Multiple Clients',
      address: '',
      tags: ['Carriers', 'Clients'],
    },
    {
      name: 'Change Port of Entry for Multiple Clients',
      address: '',
      tags: ['Clients', 'Facilities', 'Legs'],
    },
  ];

  public readonly COUNTRIES: string[] = ['CA', 'US'];
  public readonly STATES: string[] = [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ];

  public readonly PROVINCES: string[] = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

  // TODO: This should come from API
  public readonly PROCESSING_TASKS: string[] = [
    'Consolidating',
    'Cross-Docking',
    'Labeling',
    'Sorting',
    ' Verifying',
    'Administrative',
    'Tendering',
  ];
}

export const DEFAULT_GATEWAY_TAB = '201';
export const DEFAULT_REST_OF_WORLD_TAB = '201';

export const GATEWAY_FILTER_STATUSES: GatewayFilterStatus[] = [
  {
    label: 'Left Origin',
    code: '201',
  },
  {
    label: 'Arrived',
    code: '210',
  },
  {
    label: 'Closed Out',
    code: '214',
  },
  {
    label: 'Departed',
    code: '220',
  },
  {
    label: 'All Statuses',
    code: 'ALL',
  },
];

export const ROW_FILTER_STATUSES: BatchManagerFilterStatus[] = [
  {
    label: 'Left Origin',
    code: '201',
  },
  {
    label: 'Awaiting Clearance',
    code: '210',
  },
  {
    label: 'Cleared',
    code: '214',
  },
  {
    label: 'Exception',
    code: '220',
  },
  {
    label: 'All Statuses',
    code: 'ALL',
  },
];

export const DATE_RANGE_OPTIONS: string[] = [
  'ON',
  'BEFORE',
  'AFTER',
  'BETWEEN',
  'TODAY',
  'WITHIN 1 WEEK',
  'WITHIN 1 MONTH',
];

export enum FontSizeOptions {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
  XXLARGE = 'xxlarge',
}

export const FONT_SIZE_OPTIONS: Record<string, string> = {
  small: '2rem',
  medium: '5vw',
  large: '6vw',
  xlarge: '8vw',
  xxlarge: '10vw',
};

export const DEFAULT_FONT_SIZE: string = '6vw';

export const DEFAULT_COUNTRY_OPTIONS: {countryCode: string; display: string}[] = [
  {countryCode: 'CA', display: 'Canada'},
  {countryCode: 'CN', display: 'China'},
  {countryCode: 'US', display: 'USA'},
];

export const COUNTRY_CODES: Record<string, string> = {
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  WF: 'Wallis and Futuna',
  BL: 'Saint Barthelemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BT: 'Bhutan',
  JM: 'Jamaica',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  WS: 'Samoa',
  BQ: 'Bonaire, Saint Eustatius and Saba ',
  BR: 'Brazil',
  BS: 'Bahamas',
  JE: 'Jersey',
  BY: 'Belarus',
  BZ: 'Belize',
  RU: 'Russia',
  RW: 'Rwanda',
  RS: 'Serbia',
  TL: 'East Timor',
  RE: 'Reunion',
  TM: 'Turkmenistan',
  TJ: 'Tajikistan',
  RO: 'Romania',
  TK: 'Tokelau',
  GW: 'Guinea-Bissau',
  GU: 'Guam',
  GT: 'Guatemala',
  GS: 'South Georgia and the South Sandwich Islands',
  GR: 'Greece',
  GQ: 'Equatorial Guinea',
  GP: 'Guadeloupe',
  JP: 'Japan',
  GY: 'Guyana',
  GG: 'Guernsey',
  GF: 'French Guiana',
  GE: 'Georgia',
  GD: 'Grenada',
  GB: 'United Kingdom',
  GA: 'Gabon',
  SV: 'El Salvador',
  GN: 'Guinea',
  GM: 'Gambia',
  GL: 'Greenland',
  GI: 'Gibraltar',
  GH: 'Ghana',
  OM: 'Oman',
  TN: 'Tunisia',
  JO: 'Jordan',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HM: 'Heard Island and McDonald Islands',
  VE: 'Venezuela',
  PR: 'Puerto Rico',
  PS: 'Palestinian Territory',
  PW: 'Palau',
  PT: 'Portugal',
  SJ: 'Svalbard and Jan Mayen',
  PY: 'Paraguay',
  IQ: 'Iraq',
  PA: 'Panama',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PE: 'Peru',
  PK: 'Pakistan',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  ZM: 'Zambia',
  EH: 'Western Sahara',
  EE: 'Estonia',
  EG: 'Egypt',
  ZA: 'South Africa',
  EC: 'Ecuador',
  IT: 'Italy',
  VN: 'Vietnam',
  SB: 'Solomon Islands',
  ET: 'Ethiopia',
  SO: 'Somalia',
  ZW: 'Zimbabwe',
  SA: 'Saudi Arabia',
  ES: 'Spain',
  ER: 'Eritrea',
  ME: 'Montenegro',
  MD: 'Moldova',
  MG: 'Madagascar',
  MF: 'Saint Martin',
  MA: 'Morocco',
  MC: 'Monaco',
  UZ: 'Uzbekistan',
  MM: 'Myanmar',
  ML: 'Mali',
  MO: 'Macao',
  MN: 'Mongolia',
  MH: 'Marshall Islands',
  MK: 'Macedonia',
  MU: 'Mauritius',
  MT: 'Malta',
  MW: 'Malawi',
  MV: 'Maldives',
  MQ: 'Martinique',
  MP: 'Northern Mariana Islands',
  MS: 'Montserrat',
  MR: 'Mauritania',
  IM: 'Isle of Man',
  UG: 'Uganda',
  TZ: 'Tanzania',
  MY: 'Malaysia',
  MX: 'Mexico',
  IL: 'Israel',
  FR: 'France',
  IO: 'British Indian Ocean Territory',
  SH: 'Saint Helena',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands',
  FM: 'Micronesia',
  FO: 'Faroe Islands',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NA: 'Namibia',
  VU: 'Vanuatu',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NZ: 'New Zealand',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  CK: 'Cook Islands',
  XK: 'Kosovo',
  CI: 'Ivory Coast',
  CH: 'Switzerland',
  CO: 'Colombia',
  CN: 'China',
  CM: 'Cameroon',
  CL: 'Chile',
  CC: 'Cocos Islands',
  CA: 'Canada',
  CG: 'Republic of the Congo',
  CF: 'Central African Republic',
  CD: 'Democratic Republic of the Congo',
  CZ: 'Czech Republic',
  CY: 'Cyprus',
  CX: 'Christmas Island',
  CR: 'Costa Rica',
  CW: 'Curacao',
  CV: 'Cape Verde',
  CU: 'Cuba',
  SZ: 'Swaziland',
  SY: 'Syria',
  SX: 'Sint Maarten',
  KG: 'Kyrgyzstan',
  KE: 'Kenya',
  SS: 'South Sudan',
  SR: 'Suriname',
  KI: 'Kiribati',
  KH: 'Cambodia',
  KN: 'Saint Kitts and Nevis',
  KM: 'Comoros',
  ST: 'Sao Tome and Principe',
  SK: 'Slovakia',
  KR: 'South Korea',
  SI: 'Slovenia',
  KP: 'North Korea',
  KW: 'Kuwait',
  SN: 'Senegal',
  SM: 'San Marino',
  SL: 'Sierra Leone',
  SC: 'Seychelles',
  KZ: 'Kazakhstan',
  KY: 'Cayman Islands',
  SG: 'Singapore',
  SE: 'Sweden',
  SD: 'Sudan',
  DO: 'Dominican Republic',
  DM: 'Dominica',
  DJ: 'Djibouti',
  DK: 'Denmark',
  VG: 'British Virgin Islands',
  DE: 'Germany',
  YE: 'Yemen',
  DZ: 'Algeria',
  US: 'United States',
  UY: 'Uruguay',
  YT: 'Mayotte',
  UM: 'United States Minor Outlying Islands',
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LA: 'Laos',
  TV: 'Tuvalu',
  TW: 'Taiwan',
  TT: 'Trinidad and Tobago',
  TR: 'Turkey',
  LK: 'Sri Lanka',
  LI: 'Liechtenstein',
  LV: 'Latvia',
  TO: 'Tonga',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LR: 'Liberia',
  LS: 'Lesotho',
  TH: 'Thailand',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TD: 'Chad',
  TC: 'Turks and Caicos Islands',
  LY: 'Libya',
  VA: 'Vatican',
  VC: 'Saint Vincent and the Grenadines',
  AE: 'United Arab Emirates',
  AD: 'Andorra',
  AG: 'Antigua and Barbuda',
  AF: 'Afghanistan',
  AI: 'Anguilla',
  VI: 'U.S. Virgin Islands',
  IS: 'Iceland',
  IR: 'Iran',
  AM: 'Armenia',
  AL: 'Albania',
  AO: 'Angola',
  AQ: 'Antarctica',
  AS: 'American Samoa',
  AR: 'Argentina',
  AU: 'Australia',
  AT: 'Austria',
  AW: 'Aruba',
  IN: 'India',
  AX: 'Aland Islands',
  AZ: 'Azerbaijan',
  IE: 'Ireland',
  ID: 'Indonesia',
  UA: 'Ukraine',
  QA: 'Qatar',
  MZ: 'Mozambique',
};
