import {ManifestrManifest, ManifestrManifestEvent, ManifestrManifestGroup} from '../model/manifestr-manifest-group';
import {
  ManifestrManifestDto,
  ManifestrManifestEventDto,
  ManifestrManifestGroupDto,
} from '../dto/manifestr-manifest-group.dto';
import {convertBatchFileDtoToModel} from 'src/app/core/api/converters/convert-batch-file-dto-to-model';

export function convertManifestrManifestGroupDtoToModel(dto: ManifestrManifestGroupDto): ManifestrManifestGroup {
  return {
    inductionDepotCode: dto.inductionDepotCode,
    startDate: dto.startDate,
    endDate: dto.endDate,
    manifestingEvents: dto.manifestingEvents.map(event => convertManifestrManifestingEventsDtoToModel(event)),
  };
}

export function convertManifestrManifestingEventsDtoToModel(dto: ManifestrManifestEventDto): ManifestrManifestEvent {
  return {
    document: dto.document ? convertBatchFileDtoToModel(dto.document) : null,
    documentUrl: dto.documentUrl,
    groupId: dto.groupID,
    groupCaption: dto.groupCaption,
    manifests: dto.manifests.map(manifest => convertManifestrManifestDtoToModel(manifest)),
    manifestsGenerated: dto.manifestsGenerated,
    sort: dto.sort,
    totalCount: dto.totalCount,
    totalWeightLbsDisplay: dto.totalWeightLbsDisplay,
    totalWeightKgDisplay: dto.totalWeightKgDisplay,
  };
}

function convertManifestrManifestDtoToModel(dto: ManifestrManifestDto): ManifestrManifest {
  return {
    account: dto.account,
    count: dto.count,
    date: dto.date,
    document: dto.document ? convertBatchFileDtoToModel(dto.document) : null,
    documentId: dto.documentID,
    documentUrl: dto.documentUrl,
    name: dto.name,
    totalWeightLbsDisplay: dto.totalWeightLbsDisplay,
    totalWeightKgDisplay: dto.totalWeightKgDisplay,
  };
}
