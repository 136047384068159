import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {EventRecordrBatch} from 'src/app/event-recordr/shared/model/event-recordr-batch';
import {EventRecordrBatchParams} from 'src/app/event-recordr/shared/model/event-recordr-batch-params';
import {EventRecordrPiece} from 'src/app/event-recordr/shared/model/event-recordr-piece';
import {EventRecordrShipmentManagerBatch} from 'src/app/event-recordr/shared/model/event-recrodr-shipment-manager-batch';

export enum EventRecordrActionType {
  GET_BATCHES = '[Event RecordR] Get Batches',
  GET_BATCHES_SUCCESS = '[Event RecordR] Get Batches :: Success',

  GET_BATCH = '[Event RecordR] Get Batch',
  GET_BATCH_SUCCESS = '[Event RecordR] Get Batch :: Success',

  GET_PIECES = '[Event RecordR] Get Pieces',
  GET_PIECES_SUCCESS = '[Event RecordR] Get Pieces :: Success',

  GET_SHIPMENT_MANAGER_BATCHES = '[Event RecordR] Get Shipment Manager Batches',
  GET_SHIPMENT_MANAGER_BATCHES_SUCCESS = '[Event RecordR] Get Shipment Manager Batches :: Success',

  GET_SHIPMENT_MANAGER_BATCH = '[Event RecordR] Get Shipment Manager Batch',
  GET_SHIPMENT_MANAGER_BATCH_SUCCESS = '[Event RecordR] Get Shipment Manager Batch :: Success',

  CLOSEOUT_EVENT_RECORDR_BATCH = '[Event RecordR] Closeout Event RecordR Batch',
  CLOSEOUT_EVENT_RECORDR_BATCH_SUCCESS = '[Event RecordR] Closeout Event RecordR Batch :: Success',

  SET_PARAMS = '[Event RecordR] Set Params',
  SET_SCROLL_POSITION = '[Event RecordR] Set Scroll Position',
}

export class GetEventRecordrBatchesAction implements Action {
  public readonly type = EventRecordrActionType.GET_BATCHES;

  constructor(
    public payload: {
      params: EventRecordrBatchParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetEventRecordrBatchesSuccessAction implements Action {
  public readonly type = EventRecordrActionType.GET_BATCHES_SUCCESS;

  constructor(public payload: {batches: EventRecordrBatch[]; getMore: boolean}) {}
}

export class GetEventRecordrBatchAction implements Action {
  public readonly type = EventRecordrActionType.GET_BATCH;

  constructor(
    public payload: {
      batchId: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetEventRecordrBatchSuccessAction implements Action {
  public readonly type = EventRecordrActionType.GET_BATCH_SUCCESS;

  constructor(public payload: {batch: EventRecordrBatch}) {}
}

export class GetEventRecordrPiecesAction implements Action {
  public readonly type = EventRecordrActionType.GET_PIECES;

  constructor(
    public payload: {
      batchId: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetEventRecordrPiecesSuccessAction implements Action {
  public readonly type = EventRecordrActionType.GET_PIECES_SUCCESS;

  constructor(public payload: {pieces: EventRecordrPiece[]}) {}
}

export class GetEventRecordrShipmentManagerBatchesAction implements Action {
  public readonly type = EventRecordrActionType.GET_SHIPMENT_MANAGER_BATCHES;

  constructor(
    public payload: {
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetEventRecordrShipmentManagerBatchesSuccessAction implements Action {
  public readonly type = EventRecordrActionType.GET_SHIPMENT_MANAGER_BATCHES_SUCCESS;

  constructor(public payload: {batches: EventRecordrShipmentManagerBatch[]}) {}
}

export class GetEventRecordrShipmentManagerBatchAction implements Action {
  public readonly type = EventRecordrActionType.GET_SHIPMENT_MANAGER_BATCH;

  constructor(
    public payload: {
      batchId: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetEventRecordrShipmentManagerBatchSuccessAction implements Action {
  public readonly type = EventRecordrActionType.GET_SHIPMENT_MANAGER_BATCH_SUCCESS;

  constructor(public payload: {batch: EventRecordrShipmentManagerBatch}) {}
}

export class CloseoutEventRecordrBatchAction implements Action {
  public readonly type = EventRecordrActionType.CLOSEOUT_EVENT_RECORDR_BATCH;

  constructor(
    public payload: {
      batchIdentifier: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class CloseoutEventRecordrBatchActionSuccess implements Action {
  public readonly type = EventRecordrActionType.CLOSEOUT_EVENT_RECORDR_BATCH_SUCCESS;

  constructor(public payload: {result: unknown}) {}
}

export class SetEventRecordrParamsAction implements Action {
  public readonly type = EventRecordrActionType.SET_PARAMS;

  constructor(public payload: {params: EventRecordrBatchParams}) {}
}

export class SetEventRecordrScrollPositionAction implements Action {
  public readonly type = EventRecordrActionType.SET_SCROLL_POSITION;

  constructor(public payload: {scrollPosition: number}) {}
}

export type EventRecordrAction =
  | GetEventRecordrBatchesAction
  | GetEventRecordrBatchesSuccessAction
  | GetEventRecordrBatchAction
  | GetEventRecordrBatchSuccessAction
  | GetEventRecordrPiecesAction
  | GetEventRecordrPiecesSuccessAction
  | GetEventRecordrShipmentManagerBatchesAction
  | GetEventRecordrShipmentManagerBatchesSuccessAction
  | GetEventRecordrShipmentManagerBatchAction
  | GetEventRecordrShipmentManagerBatchSuccessAction
  | CloseoutEventRecordrBatchAction
  | CloseoutEventRecordrBatchActionSuccess
  | SetEventRecordrParamsAction
  | SetEventRecordrScrollPositionAction;
