import {createSelector, MemoizedSelector} from '@ngrx/store';
import {hashObject} from 'src/app/shared/utils/hash-object';
import {ClientDashboardBatch} from '../../models/client-dashboard-batch';
import {ClientDashboardParams} from '../../models/client-dashboard-summary-params';
import {AppState} from '../app.state';
import {selectRouterParam, selectRouterQueryParam} from '../router/router.selector';

export const selectClientDashboardState = (state: AppState) => state.clientDashboard;

export const selectClientDashboardParams: MemoizedSelector<AppState, ClientDashboardParams> = createSelector(
  selectRouterParam('batchId'),
  selectRouterQueryParam('search'),
  selectRouterQueryParam('page'),
  selectRouterQueryParam('pageSize'),
  selectRouterQueryParam('sort'),
  selectRouterQueryParam('sortDir'),
  (batchId, search, page, pageSize, sort, sortDir) => {
    return {
      batchId,
      search,
      page: page ? Number(page) : 1,
      pageSize: pageSize ? Number(pageSize) : 50,
      sortColumns: sort ? String(sort).split(',') : [],
      sortDirections: sortDir ? String(sortDir).split(',') : [],
    };
  }
);

export const selectClientDashboardRecentBatchesCount = createSelector(
  selectClientDashboardState,
  state => state.recentBatchesCount
);

export const selectClientDashboardRecentBatchesMap = createSelector(
  selectClientDashboardState,
  state => state.recentBatchesMap
);

export const selectClientDashboardRecentBatches = (showRecent: boolean, showClosed: boolean) =>
  createSelector(
    selectClientDashboardRecentBatchesMap,
    selectClientDashboardParams,
    (batchesMap, params) => batchesMap[hashObject({...params, showRecent, showClosed})]
  );

export const selectClientDashboardRecentBatchesLoading = (showRecent?: boolean, showClosed?: boolean) =>
  createSelector(
    selectClientDashboardRecentBatchesMap,
    selectClientDashboardParams,
    (batchesMap, params) => !batchesMap[hashObject({...params, showRecent, showClosed})]
  );

export const selectClientDashboardBatchMap = createSelector(selectClientDashboardState, state => state.batchMap);

export const selectClientDashboardBatch = createSelector(
  selectClientDashboardBatchMap,
  selectRouterParam('batchId'),
  (batchMap, batchId) => batchMap[hashObject({batchId})]
);

export const selectClientDashboardBatchLoading = createSelector(
  selectClientDashboardBatchMap,
  selectRouterParam('batchId'),
  (batchMap, batchId) => !batchMap[hashObject({batchId})]
);

export const selectClientDashboardPieceDetailParams: MemoizedSelector<AppState, ClientDashboardParams> = createSelector(
  selectRouterParam('batchId'),
  selectRouterQueryParam('search'),
  selectRouterQueryParam('page'),
  selectRouterQueryParam('pageSize'),
  selectRouterQueryParam('sort'),
  selectRouterQueryParam('sortDir'),
  (batchId, search, page, pageSize, sort, sortDir) => {
    return {
      batchId,
      search,
      page: page ? Number(page) : 1,
      pageSize: pageSize ? Number(pageSize) : 50,
      sortColumns: sort ? String(sort).split(',') : [],
      sortDirections: sortDir ? String(sortDir).split(',') : [],
    };
  }
);

export const selectClientDashboardPiecesCount = createSelector(selectClientDashboardState, state => state.piecesCount);

export const selectClientDashboardPiecesMap = createSelector(selectClientDashboardState, state => state.piecesMap);
export const selectClientDashboardPieceDetails = createSelector(
  selectClientDashboardPiecesMap,
  selectClientDashboardPieceDetailParams,
  (piecesMap, params) => piecesMap[hashObject(params)]
);

export const selectClientDashboardPiecesLoading = createSelector(
  selectClientDashboardPiecesMap,
  selectClientDashboardParams,
  (piecesMap, params) => !piecesMap[hashObject(params)]
);

export const selectClientDashboardAssociatedBatchPieceDetailParams: MemoizedSelector<AppState, ClientDashboardParams> =
  createSelector(
    selectRouterQueryParam('search'),
    selectRouterQueryParam('page'),
    selectRouterQueryParam('pageSize'),
    selectRouterQueryParam('sort'),
    selectRouterQueryParam('sortDir'),
    (search, page, pageSize, sort, sortDir) => {
      return {
        search,
        page: page ? Number(page) : 1,
        pageSize: pageSize ? Number(pageSize) : 50,
        sortColumns: sort ? String(sort).split(',') : [],
        sortDirections: sortDir ? String(sortDir).split(',') : [],
      };
    }
  );

export const selectClientDashboardAssociatedBatchPiecesCount = createSelector(
  selectClientDashboardState,
  state => state.associatedBatchPiecesCount
);

export const selectClientDashboardAssociatedBatchPiecesMap = createSelector(
  selectClientDashboardState,
  state => state.associatedBatchPiecesMap
);
export const selectClientDashboardAssociatedBatchPieceDetails = (batch: ClientDashboardBatch) =>
  createSelector(
    selectClientDashboardAssociatedBatchPiecesMap,
    selectClientDashboardAssociatedBatchPieceDetailParams,
    (piecesMap, params) =>
      piecesMap[hashObject({...params, batchId: String(batch.batchId), virtualBatchType: batch.virtualBatchType})]
  );

export const selectClientDashboardAssociatedBatchPiecesLoading = (batch: ClientDashboardBatch) =>
  createSelector(
    selectClientDashboardAssociatedBatchPiecesMap,
    selectClientDashboardAssociatedBatchPieceDetailParams,
    (piecesMap, params) =>
      !piecesMap[hashObject({...params, batchId: String(batch.batchId), virtualBatchType: batch.virtualBatchType})]
  );

export const selectClientDashboardCreatedBatch = createSelector(
  selectClientDashboardState,
  state => state.createdBatch
);

export const selectClientDashboardTrainerPackages = createSelector(
  selectClientDashboardState,
  state => state.trainerPackages
);
