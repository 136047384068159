import {ManifestrDispatchLocationDto, ManifestrDispatchLocationsDto} from '../dto/manifestr-dispatch-location.dto';
import {ManifestrDispatchLocation} from '../model/manifestr-dispatch-location';

export function convertManifestrDispatchLocationsDtoToModel(
  dto: ManifestrDispatchLocationsDto
): ManifestrDispatchLocation[] {
  return dto.DispatchLocations.map(d => convertManifestrDispatchLocationDtoToModel(d));
}

export function convertManifestrDispatchLocationDtoToModel(
  dto: ManifestrDispatchLocationDto
): ManifestrDispatchLocation {
  return {
    batchCount: dto.BatchCount,
    dispatchDepotCode: dto.DispatchDepotCode,
    dispatchDepotDisplayName: dto.DispatchDepotDisplayName,
    inductionDepotCode: dto.InductionDepotCode,
  };
}
