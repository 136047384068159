import {ApplicationId} from 'src/app/core/models/application';
import {ManifestrEditQueueParamsDto} from '../dto/manifestr-select-batches-params.dto';
import {ManifestrEditQueueParams} from '../model/manifestr-select-batches-params';

export function convertManifestrEditQueueSearchParamsModelToDto(
  model: ManifestrEditQueueParams
): ManifestrEditQueueParamsDto {
  return {
    InductionDepotCode: model.inductionDepotCode,
    BatchIDs: model.batchIds,
    appID: ApplicationId.ManifestR,
  };
}
