import {ManifestrQueueCompanyListDto, ManifestrQueueCompanyListItemDto} from '../dto/manifestr-queue-company-list.dto';
import {ManifestrQueueCompanyList, ManifestrQueueCompanyListItem} from '../model/manifestr-queue-company-list';
import {convertManifestrBatchDtoToModel} from './convert-manifestr-batch-dto-to-model';

export function convertManifestrQueueCompanyListDtoToModel(
  dto: ManifestrQueueCompanyListDto
): ManifestrQueueCompanyList {
  return {
    companyBatches: dto.CompanyBatches.map(queueCompanyListItem =>
      convertQueueCompanyListItemDtoToModel(queueCompanyListItem)
    ),
  };
}

function convertQueueCompanyListItemDtoToModel(dto: ManifestrQueueCompanyListItemDto): ManifestrQueueCompanyListItem {
  return {
    companyId: dto.CompanyID,
    companyName: dto.CompanyName,
    batchList: dto.BatchList.map(batch => convertManifestrBatchDtoToModel(batch)),
  };
}
