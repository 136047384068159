import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {I18nextClassDirective} from './i18next-class.directive';

@NgModule({
  declarations: [I18nextClassDirective],
  imports: [CommonModule],
  exports: [I18nextClassDirective],
})
export class I18nextClassModule {}
