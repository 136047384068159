import {DocumentActivity} from 'src/app/core/models/document-activity';
import {Configuration, Language, LanguageItem} from '../../models/configuration';
import {ConfigurationDto, LanguageDto, LanguageItemDto} from '../dto/configuration.dto';
import {ScanActivity, SearchOption} from './../../models/application';
import {BatchType} from './../../models/batch-type';
import {ScanActivityDto, SearchOptionDto} from './../dto/application.dto';
import {BatchTypeDto} from './../dto/batch-type.dto';
import {DocumentActivityDto} from './../dto/document-activity.dto';
import {convertApplicationCategoryDtoToModel} from './convert-application-category-dto-to-model';
import {convertApplicationDtoToModel} from './convert-application-dto-to-model';
import {convertUserInfoDtoToModel} from './convert-user-info-dto-to-model';

export function convertConfigurationDtoToModel(dto: ConfigurationDto): Configuration {
  return {
    applicationCategories: dto.ApplicationCategories?.map(app => convertApplicationCategoryDtoToModel(app)) || [],
    applications: dto.Applications?.map(app => convertApplicationDtoToModel(app)) || [],
    documentActivities: dto.DocumentActivities?.map(doc => convertDocumentActivityDtoToModel(doc)) || [],
    batchTypes: dto.BatchTypes?.map(dto => convertBatchTypeDtoToModel(dto)) || [],
    facilities: dto.Facilities?.map(dto => convertSearchOptionDtoToModel(dto)) || [],
    originFacilities: dto.OriginFacilities?.map(dto => convertSearchOptionDtoToModel(dto)) || [],
    scanActivities: dto.ScanActivities?.map(dto => convertScanActivitiesDtoToModel(dto)) || [],
    draDatabaseName: dto.DRADatabaseName,
    instance: dto.Instance,
    parsDatabaseName: dto.PARSDatabaseName,
    securityDatabaseName: dto.SecurityDatabaseName,
    userInformation: convertUserInfoDtoToModel(dto.UserInformation),
    languages: dto.Languages?.map(dto => convertLanguageDtoToModel(dto)) || [],
  };
}

function convertLanguageDtoToModel(dto: LanguageDto): Language {
  return {
    display: dto.Display,
    fontName: dto.FontName,
    languageCode: dto.LanguageCode.toLowerCase(),
    sortName: dto.SortName,
    stringValues: dto.StringValues.map(item => convertLanguageItemDtoToModel(item)),
  };
}

function convertLanguageItemDtoToModel(item: LanguageItemDto): LanguageItem {
  return {
    stringCode: item.StringCode,
    display: item.Display,
  };
}

function convertDocumentActivityDtoToModel(dto: DocumentActivityDto): DocumentActivity {
  return {
    code: dto.Code,
    description: dto.Description,
  };
}

function convertBatchTypeDtoToModel(dto: BatchTypeDto): BatchType {
  return {
    code: dto.Code,
    description: dto.Description,
  };
}

function convertSearchOptionDtoToModel(dto: SearchOptionDto): SearchOption {
  return {
    code: dto.Code,
    description: dto.Description,
  };
}

function convertScanActivitiesDtoToModel(dto: ScanActivityDto): ScanActivity {
  return {
    id: dto.ID,
    code: dto.Code,
    description: dto.Description,
  };
}
