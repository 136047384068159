import {ManifestrDestinationListDto, ManifestrDestinationListItemDto} from '../dto/manifestr-destination-list.dto';
import {ManifestrDestinationList, ManifestrDestinationListItem} from '../model/manifestr-destination-list';

export function convertManifestrDestinationListDtoToModel(dto: ManifestrDestinationListDto): ManifestrDestinationList {
  return {
    destinations: dto.Destinations.map(destination => convertDestinationListItemDtoToModel(destination)),
  };
}

function convertDestinationListItemDtoToModel(dto: ManifestrDestinationListItemDto): ManifestrDestinationListItem {
  return {
    id: dto.Id,
    displayName: dto.DisplayName,
    batchCount: dto.BatchCount,
    pieceCount: dto.PieceCount,
    weightLb: dto.WeightLB,
    weightLbDisplay: dto.WeightLBDisplay,
    weightKg: dto.WeightKG,
    weightKgDisplay: dto.WeightKGDisplay,
    piecesAdded: dto.PiecesAdded,
  };
}
