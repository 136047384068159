<header>
  <div class="empty"></div>
  <h1>Select a Display Time Zone</h1>
  <button class="close-button" (click)="onClose()">
    <img src="assets/icons/close-red.svg" />
  </button>
</header>

<div class="button-container">
  <button
    *ngFor="let option of timeZoneOptions"
    (click)="onClick(option.value)"
    class="item-button"
    [class.selected]="option.value === selectedTimezone"
  >
    <div class="icon-container">
      <img [src]="option.iconSrc" />
    </div>
    <h2 *ngIf="option.display.length === 3" class="item-text">{{ option.display[0] }} {{ option.display[1] }}</h2>
    <h2 *ngIf="option.display.length === 2" class="item-text">{{ option.display[0] }}</h2>
    <h2 class="item-text">{{ option.display[option.display.length - 1] }}</h2>
    <p class="item-subtext">{{ option.subtext }}</p>
  </button>
</div>
