import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {defaultInterpolationFormat, I18NextModule, I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'moment-duration-format';
import {Constants} from 'src/app/shared/constants';
import {SharedModule} from 'src/app/shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './auth/auth.module';
import {CoreModule} from './core/core.module';
import {MenuModule} from './menu/menu.module';

const resources = {
  en: {
    translation: {},
  },
  cn: {
    translation: {},
  },
};

export function appInit(i18next: ITranslationService) {
  return () =>
    // eslint-disable-next-line
    i18next.use<any>(LanguageDetector).init({
      fallbackLng: 'en',
      // debug: true,
      returnEmptyString: false,
      ns: ['translation', 'validation', 'error'],
      interpolation: {
        format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
      },
      resources,
    });
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AuthModule,
    MenuModule,
    AppRoutingModule,
    I18NextModule.forRoot(),
  ],
  providers: [
    Constants,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    I18N_PROVIDERS,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
