import {EventRecordrBatch} from 'src/app/event-recordr/shared/model/event-recordr-batch';
import {EventRecordrBatchParams} from 'src/app/event-recordr/shared/model/event-recordr-batch-params';
import {EventRecordrPiece} from 'src/app/event-recordr/shared/model/event-recordr-piece';
import {EventRecordrShipmentManagerBatch} from 'src/app/event-recordr/shared/model/event-recrodr-shipment-manager-batch';

export interface EventRecordrState {
  batches: EventRecordrBatch[];
  batch: EventRecordrBatch;
  pieces: EventRecordrPiece[];
  shipmentManagerBatches: EventRecordrShipmentManagerBatch[];
  shipmentManagerBatch: EventRecordrShipmentManagerBatch;
  params: EventRecordrBatchParams;
  scrollPosition: number;
}

export const initialEventRecordrState: EventRecordrState = {
  batches: [],
  batch: null,
  pieces: null,
  shipmentManagerBatches: null,
  shipmentManagerBatch: null,
  params: {startRow: 0, rowCount: 10, searchBy: ''},
  scrollPosition: null,
};
