import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {MenuItems} from 'src/app/core/models/menu-items';
import {LogoutAction} from 'src/app/core/store/auth/auth.action';
import {Constants} from 'src/app/shared/constants';
import {Application} from '../../../core/models/application';
import {ConfigurationApiService} from './../../../core/api/configuration-api.service';
import {Configuration} from './../../../core/models/configuration';
import {selectConfiguration} from './../../../core/store/workspace/workspace.selector';

@Component({
  selector: 'tracking-side-nav',
  templateUrl: './tracking-side-nav.component.html',
  styleUrls: ['./tracking-side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackingSideNavComponent implements OnInit, OnChanges {
  @Input()
  public applications: Application[];

  @Input()
  public mobile: boolean;

  @Output()
  public sideMenu = new EventEmitter();

  public applicationsMap: Record<string, Application> = {};

  public menuItems: MenuItems[];
  public activeRoute: string;

  public configuration$: Observable<Configuration>;

  constructor(
    private configurationApiService: ConfigurationApiService,
    private constants: Constants,
    public router: Router,
    private store$: Store
  ) {}

  ngOnInit(): void {
    this.configuration$ = this.store$.pipe(select(selectConfiguration));
    const urlTree = this.router.parseUrl(this.router.url);
    this.activeRoute = `/${urlTree.root.children['primary']?.segments[0]?.path}`;
    this.menuItems = this.constants.MENU_ITEMS_TRACKING.map(m => {
      // Set initial icon img src
      // to be toggled later on hover & activeRoute change
      m.iconSrc = m.address === this.activeRoute ? m.activeIcon : m.inactiveIcon;
      return m;
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.applications && this.applications) {
      this.applicationsMap = this.applications.reduce((appsMap, app) => ((appsMap[app.id] = app), appsMap), {});

      // TODO: remove when real data is coming in
      this.applicationsMap['ClientDashboardTrainer'] = this.applicationsMap['ClientDashboard'];
    }
  }

  public onNavItem(name: string) {
    this.configuration$.pipe(take(1)).subscribe(configuration => {
      const application = configuration?.applications.find(app => app.id === this.findAppId(name));
      if (application) {
        this.configurationApiService.sendLogInfo(application.appGuid).subscribe();
      }
    });
  }

  public findAppId(name: string) {
    switch (name) {
      case 'Gateway Batch Manager':
        return 'GBM';
      case 'Document Repository':
        return 'Documents';
      case 'Shipment ManageR':
        return 'ClientDashboard';
      default:
        return name.replace(' ', '');
    }
  }

  public onLogoutClick() {
    this.store$.dispatch(new LogoutAction());
  }

  public onLoginClick() {
    this.router.navigate(['/login']);
  }

  public onOpen() {
    this.sideMenu.emit();
  }
}
