import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import {TimeZoneOption} from '../shared/time-zone-option';

@Component({
  selector: 'dra-timezone-dialog',
  templateUrl: './dra-timezone-dialog.component.html',
  styleUrls: ['./dra-timezone-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraTimezoneDialogComponent {
  selectedTimezone: string;

  timeZoneOptions: TimeZoneOption[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: {
      selectedTimezone: string;
      timeZoneOptions: TimeZoneOption[];
    },
    private dialogRef: MatDialogRef<DraTimezoneDialogComponent>
  ) {
    this.selectedTimezone = data.selectedTimezone;
    this.timeZoneOptions = data.timeZoneOptions;
  }

  onClick(value: string) {
    this.dialogRef.close(value);
  }

  onClose() {
    this.dialogRef.close();
  }
}
