import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Language} from 'src/app/core/models/configuration';

@Component({
  selector: 'dra-language-selector',
  templateUrl: './dra-language-selector.component.html',
  styleUrls: ['./dra-language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraLanguageSelectorComponent implements OnChanges {
  @Input() languages: Language[];
  @Input() selectedLanguage: string;

  @Output() valueChange = new EventEmitter<string>();

  currentLanguage: Language;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.languages || changes.language) {
      this.getCurrentLanguage();
    }
  }

  getCurrentLanguage() {
    if (this.languages && this.selectedLanguage) {
      this.currentLanguage =
        this.languages.find(language => language.languageCode === this.selectedLanguage) || ({} as Language);
    }
  }

  public open$ = new BehaviorSubject<boolean>(false);

  public menuOpened() {
    this.open$.next(true);
  }

  public menuClosed() {
    this.open$.next(false);
  }

  public onLanguageChange(language: string) {
    if (this.selectedLanguage !== language) {
      this.valueChange.emit(language.toLowerCase());
    }
  }
}
