import {MatSidenavModule} from '@angular/material/sidenav';
import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {RouterModule} from '@angular/router';
import {MobileModule} from '../mobile/mobile.module';
import {PipesModule} from '../pipes/pipes.module';
import {CompanySelectComponent} from './header/company-select/company-select.component';
import {MobileTrackingHeaderComponent} from './header/mobile/mobile-tracking-header.component';
import {MobileHeaderSearchComponent} from './header/mobile/search/mobile-header-search.component';
import {TrackingHeaderComponent} from './header/tracking-header.component';
import {PageWrapperComponent} from './page-wrapper/page-wrapper.component';
import {TrackingSearchComponent} from './search/tracking-search.component';
import {TrackingSideNavComponent} from './side-nav/tracking-side-nav.component';
import {BarcodeScannerModule} from '../barcode-scanner/barcode-scanner.module';
import {TrackingDrawerNavComponent} from './drawer-nav/tracking-drawer-nav.component';
import {LoadingResultsModule} from '../loading-results/loading-results.module';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {LoadingConfigurationModule} from '../loading-configuration/loading-configuration.module';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {TrainingModeModule} from '../training-mode/training-mode.module';
import {ConfigurationLoadingErrorComponent} from './configuration-loading-error/configuration-loading-error.component';
import {DesktopLayoutComponent} from './desktop/desktop-layout.component';
import {MobileLayoutComponent} from './mobile/mobile-layout.component';
import {DraLanguageSelectorModule} from '../dra-language-selector/dra-language-selector.module';
import {DraTimezoneSelectorModule} from '../dra-timezone-selector/dra-timezone-selector.module';

@NgModule({
  declarations: [
    PageWrapperComponent,
    TrackingSideNavComponent,
    TrackingHeaderComponent,
    TrackingSearchComponent,
    MobileTrackingHeaderComponent,
    MobileHeaderSearchComponent,
    CompanySelectComponent,
    TrackingDrawerNavComponent,
    ConfigurationLoadingErrorComponent,
    DesktopLayoutComponent,
    MobileLayoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    MobileModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    PipesModule,
    ReactiveFormsModule,
    OverlayModule,
    MatSidenavModule,
    BarcodeScannerModule,
    LoadingResultsModule,
    MatProgressSpinnerModule,
    LoadingConfigurationModule,
    MatDialogModule,
    TrainingModeModule,
    DraLanguageSelectorModule,
    DraTimezoneSelectorModule,
  ],
  exports: [PageWrapperComponent, TrackingSearchComponent, CompanySelectComponent],
})
export class TrackingLayoutModule {}
