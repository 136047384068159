import {HttpErrorResponse} from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, take, withLatestFrom} from 'rxjs/operators';
import {AuthToken} from 'src/app/core/models/auth-token';
import {Lesson} from 'src/app/core/models/lesson';
import {UserInfo} from 'src/app/core/models/user-info';
import {MessageService} from 'src/app/core/services';
import {selectAuthenticated} from 'src/app/core/store/auth/auth.selector';
import {selectBatchTrackingDetailDrawerStatus} from 'src/app/core/store/batch-tracking/batch-tracking.selectors';
import {selectRouterUrl} from 'src/app/core/store/router/router.selector';
import {Configuration} from '../../../core/models/configuration';
import {MobileService} from '../../../core/page/mobile.service';
import {
  selectConfiguration,
  selectOfflineMode,
  selectPageWrapperPaddingColor,
  selectRightDrawerOpened,
  selectUserInfo,
} from '../../../core/store/workspace/workspace.selector';
import {MOCK_LESSONS} from '../../training-mode/MOCK_LESSONS';
import {findWorkspaceUrl} from '../../utils/workspace/findWorkspaceUrl';
import {PageTitleService} from './../../../core/page/page-title.service';
import {
  GetConfigurationAction,
  GetUserInfoAction,
  UpdateRightDrawerStatusAction,
  UpdateSidenavStatusAction,
} from './../../../core/store/workspace/workspace.action';
import {selectSidenavOpened} from './../../../core/store/workspace/workspace.selector';

@Component({
  selector: 'page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() hide: boolean;

  private subscription = new Subscription();

  @ViewChild('desktopDrawer') sidenav: MatSidenav;

  public userInfo$: Observable<UserInfo>;
  public authenticated$: Observable<boolean>;
  public configuration$: Observable<Configuration>;
  public mobile$: Observable<boolean>;
  public authToken$: Observable<AuthToken>;
  public pageTitle$: Observable<string>;
  public sideNavOpened$: Observable<boolean>;
  public showCondensedSideNav$: Observable<boolean>;
  public batchTrackingDetailDrawerOpened$: Observable<boolean>;
  public rightDrawerOpened$: Observable<boolean>;
  public url$: Observable<string>;
  public paddingColor$: Observable<string>;
  public loading$ = new BehaviorSubject<boolean>(null);
  public trainingMode$: Observable<boolean>;
  public offlineMode$: Observable<boolean>;

  public innerWidth$ = new BehaviorSubject<number>(null);
  public showTopNav$ = new BehaviorSubject<boolean>(true);
  public showSideNav$ = new BehaviorSubject<boolean>(true);
  public configurationLoadingError$ = new BehaviorSubject<boolean>(false);
  public trainingModeMobileOpen$ = new BehaviorSubject<boolean>(false);
  public selectedLanguage$ = new BehaviorSubject<string>('');
  public defaultPadding$ = new BehaviorSubject<boolean>(true);

  public lessons: Lesson[];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth$.next(event.target.innerWidth);
  }

  constructor(
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
    private mobileService: MobileService,
    private pageTitleService: PageTitleService,
    private messageService: MessageService,
    private router: Router,
    private store$: Store<{}>
  ) {}

  public ngOnInit(): void {
    this.selectedLanguage$.next(this.i18NextService.language || 'en');
    this.lessons = MOCK_LESSONS;
    this.userInfo$ = this.store$.select(selectUserInfo);
    this.authenticated$ = this.store$.pipe(select(selectAuthenticated));
    this.configuration$ = this.store$.pipe(select(selectConfiguration));
    this.offlineMode$ = this.store$.pipe(select(selectOfflineMode));
    this.mobile$ = this.mobileService.observeMobile();
    this.pageTitle$ = this.pageTitleService.getPageTitle();
    this.sideNavOpened$ = this.store$.pipe(select(selectSidenavOpened));
    this.rightDrawerOpened$ = this.store$.pipe(select(selectRightDrawerOpened));
    this.batchTrackingDetailDrawerOpened$ = this.store$.pipe(select(selectBatchTrackingDetailDrawerStatus));
    this.url$ = this.store$.pipe(select(selectRouterUrl));
    this.paddingColor$ = this.store$.pipe(select(selectPageWrapperPaddingColor));
    this.trainingMode$ = this.observeTrainingMode();

    this.showCondensedSideNav$ = this.observeInnerWidth();

    this.subscription.add(this.subscribeToUserInfo());
    this.subscription.add(this.subscribeToConfiguration());
    this.subscription.add(this.subscribeToUrl());
  }

  public ngAfterViewInit() {
    this.innerWidth$.next(window.innerWidth);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private subscribeToUserInfo(): Subscription {
    return this.userInfo$
      .pipe(
        filter(userInfo => !userInfo),
        withLatestFrom(this.authenticated$)
      )
      .subscribe(([, authenticated]) => {
        if (authenticated) {
          this.store$.dispatch(
            new GetUserInfoAction({
              onFailure: err => this.onGetUserInfoFailure(err),
            })
          );
        }
      });
  }

  private subscribeToUrl(): Subscription {
    return this.url$.pipe(distinctUntilChanged()).subscribe(url => {
      const noDefaultPadding =
        !url?.startsWith('/dashboard') &&
        !url?.startsWith('/shipment-manager-trainer') &&
        !url?.startsWith('/event-recordr') &&
        !url?.startsWith('/manifestr');
      this.defaultPadding$.next(noDefaultPadding);
      if (url?.startsWith('/event-recordr')) {
        this.showSideNav$.next(false);
        this.showTopNav$.next(false);
      }
    });
  }

  private onGetUserInfoFailure(err: HttpErrorResponse) {
    this.offlineMode$.pipe(take(1)).subscribe(() => {
      this.messageService.add(err.message || 'There was an error getting user info.');
    });
  }

  private subscribeToConfiguration(): Subscription {
    return this.configuration$.pipe(withLatestFrom(this.authenticated$)).subscribe(([config, authenticated]) => {
      const i18nextLocalStorageItem = localStorage.getItem('i18nextLng');
      if (config?.userInformation && !i18nextLocalStorageItem) {
        localStorage.setItem('i18nextLng', config.userInformation.languageCode.toLowerCase());
        document.location.reload();
      }
      if (config?.languages?.length) {
        config.languages.forEach(language => {
          const translations = {};
          language.stringValues.forEach(value => {
            translations[value.stringCode] = value.display;
          });
          this.i18NextService.addResourceBundle(language.languageCode.toLowerCase(), 'translation', translations);
        });
      }
      if (config?.applications?.length === 1) {
        this.router.navigate([findWorkspaceUrl(config.applications[0].id)]).then(() => {
          this.showSideNav$.next(false);
          this.loading$.next(false);
        });
      }
      if (config?.applications.length > 1) {
        this.loading$.next(false);
      }
      if (!config && authenticated) {
        this.loading$.next(true);
        this.store$.dispatch(
          new GetConfigurationAction({
            force: true,
            onFailure: () => this.onGetConfigurationFailure(),
          })
        );
      }
    });
  }

  private observeTrainingMode(): Observable<boolean> {
    return this.url$.pipe(map(url => url?.startsWith('/shipment-manager-trainer')));
  }

  private observeInnerWidth(): Observable<boolean> {
    return this.innerWidth$.pipe(map(width => width > 599));
  }

  public toggleDesktopDrawer() {
    this.sideNavOpened$.pipe(take(1)).subscribe(opened => {
      this.store$.dispatch(new UpdateSidenavStatusAction({opened: !opened}));
    });
  }

  public onRightDrawerBackdropClick() {
    this.store$.dispatch(new UpdateRightDrawerStatusAction({opened: false}));
    this.trainingModeMobileOpen$.next(false);
  }

  private onGetConfigurationFailure() {
    this.loading$.next(false);
    this.offlineMode$.pipe(take(1)).subscribe(() => {
      this.configurationLoadingError$.next(true);
    });
    // this.errorMessage$.next(err.message || 'There was an error getting the Configuration');
  }

  public onTrainingModeMobileDrawerToggle() {
    this.store$.dispatch(new UpdateRightDrawerStatusAction({opened: true}));
    this.trainingModeMobileOpen$.next(true);
  }

  onLanguageChange(lang: string) {
    if (lang === this.i18NextService.language) {
      return;
    }
    if (lang !== this.i18NextService.language) {
      this.i18NextService.changeLanguage(lang).then(() => {
        document.location.reload();
      });
    }
  }
}
