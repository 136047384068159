import {PieceInspectorNewBatchParams} from './../../models/piece-inspector-new-batch-params';
import {PieceInspectorScanPiece} from './../../models/piece-inspector-scan-piece';
import {PieceInspectorPieceParams} from './../../models/piece-inspector-piece-params';
import {HttpErrorResponse} from '@angular/common/http';
/* eslint-disable */
import {Action} from '@ngrx/store';
import {PieceInspectorBatch} from './../../models/piece-inspector-batch';
import {PieceInspectorBatchSearchParams} from './../../models/piece-inspector-batch-search-params';
import {GameCreationParams} from '../../models/game-creation-params';
import {PieceInspectorRecentBatchesParams} from '../../models/piece-inspector-recent-batches-params';

export enum PieceInspectorActionType {
  GET_BATCHES = '[Piece Inspector] Get Batches',
  GET_BATCHES_SUCCESS = '[Piece Inspector] Get Batches :: Success',

  GET_RECENT_BATCHES = '[Piece Inspector] Get Recent Batches',
  GET_RECENT_BATCHES_SUCCESS = '[Piece Inspector] Get Recent Batches :: Success',

  GET_BATCH_FROM_SCAN = '[Piece Inspector] Get Batch from Scan',
  GET_BATCH_FROM_SCAN_SUCCESS = '[Piece Inspector] Get Batch from Scan :: Success',
  CLEAR_BATCH_FROM_SCAN = '[Piece Inspector] Clear Batch from Scan',

  GET_BATCH = '[Piece Inspector] Get Batch',
  GET_BATCH_SUCCESS = '[Piece Inspector] Get Batch :: Success',
  CLEAR_BATCH = '[Piece Inspector] Clear Batch',

  SCAN_PIECE = '[Piece Inspector] Scan Piece',
  SCAN_PIECE_SUCCESS = '[Piece Inspector] Scan Piece :: Sucess',
  CLEAR_SCAN_PIECE = '[Piece Inspector] Clear Scan Piece',

  NEW_BATCH = '[Piece Inspector] New Batch',
  NEW_BATCH_SUCCESS = '[Piece Inspector] New Batch :: Success',

  GET_CLOSEOUT_BATCHES = '[Piece Inspector] Get Closeout Batches',
  GET_CLOSEOUT_BATCHES_SUCCESS = '[Piece Inspector] Get Closeout Batches :: Success',

  //GAME

  GET_RECENT_GAMES = '[Piece Inspector] Get Recent Games',
  GET_RECENT_GAMES_SUCCESS = '[Piece Inspector] Get Recent Games :: Success',

  GET_GAME_TEMPLATES = '[Piece Inspector] Get Game Templates',
  GET_GAME_TEMPLATES_SUCCESS = '[Piece Inspector] Get Game Templates :: Success',

  GET_GAME_DETAILS = '[Piece Inspector] Get Game Details',
  GET_GAME_DETAILS_SUCCESS = '[Piece Inspector] Get Game Details :: Success',

  CREATE_NEW_GAME_FROM_TEMPLATE = '[Piece Inspector] Create New Game From Template',
  CREATE_NEW_GAME_FROM_TEMPLATE_SUCCESS = '[Piece Inspector] Create New Game From Template :: Success',

  UPDATE_GAME = '[Piece Inspector] Update Game',
  UPDATE_GAME_SUCCESS = '[Piece Inspector] Update Game :: Success',

  CLEAR_GAME_STATE = '[Piece Inspector] Clear Game State',

  SCAN_GAME_PIECE = '[Piece Inspector] Scan Game Piece',
  SCAN_GAME_PIECE_SUCCESS = '[Piece Inspector] Scan Game Piece :: Sucess',
  CLEAR_GAME_SCAN_PIECE = '[Piece Inspector] Clear Game Scan Piece',

  CLEAR_GAME_DETAILS = '[Piece Inspector] Clear Game Details',
}

export class GetPieceInspectorBatchesAction implements Action {
  public readonly type = PieceInspectorActionType.GET_BATCHES;

  constructor(public payload: {params: PieceInspectorBatchSearchParams}) {}
}

export class GetPieceInspectorBatchesSuccessAction implements Action {
  public readonly type = PieceInspectorActionType.GET_BATCHES_SUCCESS;

  constructor(
    public payload: {params: PieceInspectorBatchSearchParams; batches: PieceInspectorBatch[]; batchesCount: number}
  ) {}
}

export class GetPieceInspectorRecentBatchesAction implements Action {
  public readonly type = PieceInspectorActionType.GET_RECENT_BATCHES;

  constructor(public payload: {params: PieceInspectorRecentBatchesParams}) {}
}

export class GetPieceInspectorRecentBatchesSuccessAction implements Action {
  public readonly type = PieceInspectorActionType.GET_RECENT_BATCHES_SUCCESS;

  constructor(public payload: {batches: PieceInspectorBatch[]}) {}
}

export class GetPieceInspectorBatchesFromScanAction implements Action {
  public readonly type = PieceInspectorActionType.GET_BATCH_FROM_SCAN;

  constructor(
    public payload: {
      barcode: string;
      facilityCode: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetPieceInspectorBatchesFromScanSuccessAction implements Action {
  public readonly type = PieceInspectorActionType.GET_BATCH_FROM_SCAN_SUCCESS;

  constructor(public payload: {batches: PieceInspectorBatch[]}) {}
}

export class ClearPieceInspectorBatchesFromScanAction implements Action {
  public readonly type = PieceInspectorActionType.CLEAR_BATCH_FROM_SCAN;

  constructor() {}
}

export class GetPieceInspectorBatchAction implements Action {
  public readonly type = PieceInspectorActionType.GET_BATCH;

  constructor(
    public payload: {batchId: string; onSuccess?: (batch) => void; onFailure?: (error: HttpErrorResponse) => void}
  ) {}
}

export class GetPieceInspectorBatchSuccessAction implements Action {
  public readonly type = PieceInspectorActionType.GET_BATCH_SUCCESS;

  constructor(public payload: {batch: PieceInspectorBatch}) {}
}

export class ClearPieceInspectorBatchAction implements Action {
  public readonly type = PieceInspectorActionType.CLEAR_BATCH;
}

export class ScanPieceInspectorPieceAction implements Action {
  public readonly type = PieceInspectorActionType.SCAN_PIECE;

  constructor(
    public payload: {
      params: PieceInspectorPieceParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class ScanPieceInspectorPieceSuccessAction implements Action {
  public readonly type = PieceInspectorActionType.SCAN_PIECE_SUCCESS;

  constructor(public payload: {piece: PieceInspectorScanPiece}) {}
}

export class PieceInspectorNewBatchAction implements Action {
  public readonly type = PieceInspectorActionType.NEW_BATCH;

  constructor(
    public payload: {
      params: PieceInspectorNewBatchParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class PieceInspectorNewBatchSuccessAction implements Action {
  public readonly type = PieceInspectorActionType.NEW_BATCH_SUCCESS;

  constructor(public payload: {batch: PieceInspectorBatch}) {}
}

export class PieceInspectorClearScanPieceAction implements Action {
  public readonly type = PieceInspectorActionType.CLEAR_SCAN_PIECE;

  constructor() {}
}

export class GetPieceInspectorCloseoutBatchesAction implements Action {
  public readonly type = PieceInspectorActionType.GET_CLOSEOUT_BATCHES;

  constructor(public payload: {params: PieceInspectorBatchSearchParams}) {}
}

export class GetPieceInspectorCloseoutBatchesSuccessAction implements Action {
  public readonly type = PieceInspectorActionType.GET_CLOSEOUT_BATCHES_SUCCESS;

  constructor(
    public payload: {params: PieceInspectorBatchSearchParams; batches: PieceInspectorBatch[]; batchesCount: number}
  ) {}
}

//------------------------GAME------------------------------

export class GetPieceInspectorRecentGamesAction implements Action {
  public readonly type = PieceInspectorActionType.GET_RECENT_GAMES;

  constructor() {}
}

export class GetPieceInspectorRecentGamesSuccessAction implements Action {
  public readonly type = PieceInspectorActionType.GET_RECENT_GAMES_SUCCESS;

  constructor(public payload: {batches: PieceInspectorBatch[]}) {}
}
export class GetPieceInspectorGameTemplatesAction implements Action {
  public readonly type = PieceInspectorActionType.GET_GAME_TEMPLATES;

  constructor(public payload: {params: PieceInspectorBatchSearchParams}) {}
}

export class GetPieceInspectorGameTemplatesSuccessAction implements Action {
  public readonly type = PieceInspectorActionType.GET_GAME_TEMPLATES_SUCCESS;

  constructor(
    public payload: {params: PieceInspectorBatchSearchParams; batches: PieceInspectorBatch[]; batchesCount: number}
  ) {}
}

export class GetPieceInspectorGameDetailsAction implements Action {
  public readonly type = PieceInspectorActionType.GET_GAME_DETAILS;

  constructor(
    public payload: {batchId: string; onSuccess?: () => void; onFailure?: (error: HttpErrorResponse) => void}
  ) {}
}

export class GetPieceInspectorGameDetailsSuccessAction implements Action {
  public readonly type = PieceInspectorActionType.GET_GAME_DETAILS_SUCCESS;

  constructor(public payload: {batch: PieceInspectorBatch}) {}
}

export class CreateNewGameFromTemplateAction implements Action {
  public readonly type = PieceInspectorActionType.CREATE_NEW_GAME_FROM_TEMPLATE;

  constructor(
    public payload: {
      params: GameCreationParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class CreateNewGameFromTemplateSuccessAction implements Action {
  public readonly type = PieceInspectorActionType.CREATE_NEW_GAME_FROM_TEMPLATE_SUCCESS;

  constructor(public payload: {params: GameCreationParams}) {}
}

export class PieceInspectorUpdateGameAction implements Action {
  public readonly type = PieceInspectorActionType.UPDATE_GAME;

  constructor(
    public payload: {
      status: string;
      batchNumbers: string[];
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class PieceInspectorUpdateGameSuccessAction implements Action {
  public readonly type = PieceInspectorActionType.UPDATE_GAME_SUCCESS;

  constructor(public payload: {status: string}) {}
}

export class PieceInspectorClearGameStateAction implements Action {
  public readonly type = PieceInspectorActionType.CLEAR_GAME_STATE;

  constructor() {}
}

export class ScanPieceInspectorGamePieceAction implements Action {
  public readonly type = PieceInspectorActionType.SCAN_GAME_PIECE;

  constructor(
    public payload: {
      params: PieceInspectorPieceParams;
      onSuccess?: (code?: string) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class ScanPieceInspectorGamePieceSuccessAction implements Action {
  public readonly type = PieceInspectorActionType.SCAN_GAME_PIECE_SUCCESS;

  constructor(public payload: {piece: PieceInspectorScanPiece}) {}
}

export class ClearGameDetailsAction implements Action {
  public readonly type = PieceInspectorActionType.CLEAR_GAME_DETAILS;

  constructor() {}
}

export type PieceInspectorAction =
  | GetPieceInspectorBatchesAction
  | GetPieceInspectorBatchesSuccessAction
  | GetPieceInspectorRecentBatchesAction
  | GetPieceInspectorRecentBatchesSuccessAction
  | GetPieceInspectorBatchesFromScanAction
  | GetPieceInspectorBatchesFromScanSuccessAction
  | ClearPieceInspectorBatchesFromScanAction
  | GetPieceInspectorBatchAction
  | GetPieceInspectorBatchSuccessAction
  | ClearPieceInspectorBatchAction
  | ScanPieceInspectorPieceAction
  | ScanPieceInspectorPieceSuccessAction
  | PieceInspectorClearScanPieceAction
  | PieceInspectorNewBatchAction
  | PieceInspectorNewBatchSuccessAction
  | GetPieceInspectorCloseoutBatchesAction
  | GetPieceInspectorCloseoutBatchesSuccessAction
  | GetPieceInspectorRecentGamesAction
  | GetPieceInspectorRecentGamesSuccessAction
  | GetPieceInspectorGameTemplatesAction
  | GetPieceInspectorGameTemplatesSuccessAction
  | CreateNewGameFromTemplateAction
  | CreateNewGameFromTemplateSuccessAction
  | PieceInspectorUpdateGameAction
  | PieceInspectorUpdateGameSuccessAction
  | PieceInspectorClearGameStateAction
  | ScanPieceInspectorGamePieceAction
  | ScanPieceInspectorGamePieceSuccessAction
  | ClearGameDetailsAction;
