import {hashObject} from 'src/app/shared/utils/hash-object';
import {ManifestrAction, ManifestrActionType} from './manifestr.actions';
import {initialManifestrState, ManifestrState} from './manifestr.state';

export function manifestrReducer(state = initialManifestrState, action: ManifestrAction): ManifestrState {
  switch (action.type) {
    case ManifestrActionType.GET_DISPATCH_GATEWAYS_SUCCESS:
      return {
        ...state,
        dispatchLocations: action.payload.dispatchLocations,
      };
    case ManifestrActionType.CLEAR_DISPATCH_GATEWAYS:
      return {...state, dispatchLocations: []};
    case ManifestrActionType.GET_BATCHES_SUCCESS: {
      const queryBatchesMap = {
        ...state.queryBatchesMap,
        [hashObject(action.payload.params)]: action.payload.batches,
      };
      return {...state, queryBatchesMap, batches: action.payload.batches};
    }
    case ManifestrActionType.SELECT_BATCHES_SUCCESS:
      return {...state, manifestList: action.payload.manifestList};
    case ManifestrActionType.CLEAR_BATCHES:
      return {...state, batches: null};
    case ManifestrActionType.GET_QUEUE_BY_DESTINATION_SUCCESS:
      return {...state, queueDestinationList: action.payload.destinationList};
    case ManifestrActionType.GET_QUEUE_BY_COMPANY_SUCCESS:
      return {...state, queueCompanyList: action.payload.queueCompanyList};
    case ManifestrActionType.GET_MANIFESTS_SUCCESS:
      return {...state, manifestGroup: action.payload.manifestGroup};
    case ManifestrActionType.GET_SINGLE_MANIFEST_SUCCESS:
      return {...state, singleManifestEvent: action.payload.manifestEvent};
    case ManifestrActionType.UPDATE_BATCH_SEARCH_PARAMS:
      return {...state, batchSearchParams: action.payload.params};
    case ManifestrActionType.UPDATE_MANIFEST_PARAMS:
      return {...state, manifestHistoryParams: action.payload.params};
    default:
      return state;
  }
}
