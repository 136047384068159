import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {selectOfflineMode} from 'src/app/core/store/workspace/workspace.selector';
import {LoginAction} from '../../../core/store/auth/auth.action';
import {LoginCredentialsFormControl} from './login-credentials-form-control';

@Component({
  selector: 'login-credentials',
  templateUrl: './login-credentials.component.html',
  styleUrls: ['./login-credentials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginCredentialsComponent {
  public readonly form = new UntypedFormGroup({
    [LoginCredentialsFormControl.Username]: new UntypedFormControl('', Validators.required),
    [LoginCredentialsFormControl.Password]: new UntypedFormControl('', Validators.required),
  });

  public offlineMode$: Observable<boolean>;

  public loading$ = new BehaviorSubject(false);
  public loginError$ = new BehaviorSubject<string>('');

  constructor(private router: Router, private store$: Store<{}>) {
    this.offlineMode$ = this.store$.pipe(select(selectOfflineMode));
  }

  public onSubmit() {
    this.usernameControl.markAsTouched();
    this.passwordControl.markAsTouched();

    if (this.form.invalid || this.loading$.getValue()) {
      return;
    }

    this.loading$.next(true);
    this.store$.dispatch(
      new LoginAction({
        username: this.usernameControl.value,
        password: this.passwordControl.value,
        url: window.location.hostname,
        onSuccess: () => this.onLoginSuccess(),
        onFailure: err => this.onLoginFailure(err),
      })
    );
  }

  public onLoginSuccess() {
    this.loading$.next(false);
    this.router.navigate(['home']);
  }

  public onLoginFailure(err: HttpErrorResponse) {
    this.loading$.next(false);
    this.offlineMode$.pipe(take(1)).subscribe(() => {
      if (err.status === 401) {
        this.loginError$.next('Incorrect username or password.');
      } else {
        this.loginError$.next('Request could not be processed. Please try again later.');
      }
    });
  }

  public get usernameControl(): AbstractControl {
    return this.form.get(LoginCredentialsFormControl.Username);
  }

  public get passwordControl(): AbstractControl {
    return this.form.get(LoginCredentialsFormControl.Password);
  }
}
