import {ManifestrSearchParamsDto} from '../dto/manifestr-search-params.dto';
import {ManifestrSearchParams} from '../model/manifestr-search-params';
import * as moment from 'moment';

export function convertManifestrDispatchParamsModelToDto(model: ManifestrSearchParams): ManifestrSearchParamsDto {
  delete model.dispatchGateways;
  const formattedModel = {
    ...model,
    startDate: model.startDate ? formatDate(model.startDate) : null,
    endDate: model.endDate ? formatDate(model.endDate) : null,
    groupID: model.groupId ? model.groupId : null,
    groupId: undefined,
  };
  return Object.entries(formattedModel)
    .filter(([, value]) => value || value === 0)
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    );
}

function formatDate(date: moment.Moment): string {
  return date.format('YYYY-MM-DD').toString();
}
