import {EventRecordrBatchParamsDto} from '../dto/event-recordr-batch-params.dto';
import {EventRecordrBatchParams} from '../model/event-recordr-batch-params';

export function exportEventRecordrBatchParamsModelToDto(model: EventRecordrBatchParams): EventRecordrBatchParamsDto {
  return {
    StartRow: model.startRow,
    RowCount: model.rowCount,
    CompanyID: model.companyId,
    TransitStatusType: model.transitStatusType,
    OrderBy: model.orderBy,
    searchBy: model.searchBy,
  };
}
