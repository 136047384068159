import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ManifestrBatchListDto} from 'src/app/manifestr/shared/dto/manifestr-batch-list.dto';
import {ManifestrBatchDto} from 'src/app/manifestr/shared/dto/manifestr-batch.dto';
import {ManifestrDestinationListDto} from 'src/app/manifestr/shared/dto/manifestr-destination-list.dto';
import {ManifestrDispatchLocationsDto} from 'src/app/manifestr/shared/dto/manifestr-dispatch-location.dto';
import {ManifestrManifestGroupDto} from 'src/app/manifestr/shared/dto/manifestr-manifest-group.dto';
import {ManifestrManifestListDto} from 'src/app/manifestr/shared/dto/manifestr-manifest-list.dto';
import {ManifestrQueueCompanyListDto} from 'src/app/manifestr/shared/dto/manifestr-queue-company-list.dto';
import {ManifestrSearchParamsDto} from 'src/app/manifestr/shared/dto/manifestr-search-params.dto';
import {ManifestrEditQueueParamsDto} from 'src/app/manifestr/shared/dto/manifestr-select-batches-params.dto';
import {ManifestrManifestEvent} from 'src/app/manifestr/shared/model/manifestr-manifest-group';
import {ConfigurationApiService} from './configuration-api.service';
import {MANIFESTR_MOCK_MANIFEST_GROUP} from 'src/app/manifestr/shared/manifestr-mock-data';

@Injectable({
  providedIn: 'root',
})
export class ManifestrApiService {
  constructor(private http: HttpClient, private configService: ConfigurationApiService) {}

  public mockEndpoint(params: ManifestrSearchParamsDto): Observable<ManifestrBatchDto[]> {
    return this.http.get<ManifestrBatchDto[]>(`${this.configService.getEndpointUrl()}/Manifestr`, {
      params: JSON.parse(JSON.stringify({params})),
    });
  }

  public getDispatchLocations(params: ManifestrSearchParamsDto): Observable<ManifestrDispatchLocationsDto> {
    return this.http.get<ManifestrDispatchLocationsDto>(
      `${this.configService.getEndpointUrl()}/batches/dispatchLocations`,
      {
        params: JSON.parse(JSON.stringify({...params})),
      }
    );
  }

  public getBatches(params: ManifestrSearchParamsDto): Observable<ManifestrBatchListDto> {
    return this.http.get<ManifestrBatchListDto>(`${this.configService.getEndpointUrl()}/batches`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public selectBatches(params: ManifestrEditQueueParamsDto): Observable<ManifestrManifestListDto> {
    return this.http.put<ManifestrManifestListDto>(`${this.configService.getEndpointUrl()}/batches/manifestr/queue`, {
      ...params,
    });
  }

  public removeBatches(params: ManifestrEditQueueParamsDto) {
    return this.http.delete<ManifestrManifestListDto>(
      `${this.configService.getEndpointUrl()}/batches/manifestr/queue?InductionDepotCode=${String(
        params.InductionDepotCode
      )}&BatchIDs=${String(params.BatchIDs.join(','))}&appID=${String(params.appID)}`
    );
  }

  public getQueueByDestination(inductionDepotCode: string): Observable<ManifestrDestinationListDto> {
    return this.http.get<ManifestrDestinationListDto>(
      `${this.configService.getEndpointUrl()}/batches/manifestr/queueByDestination`,
      {
        params: JSON.parse(JSON.stringify({inductionDepotCode})),
      }
    );
  }

  public getQueueByCompany(inductionDepotCode: string): Observable<ManifestrQueueCompanyListDto> {
    return this.http.get<ManifestrQueueCompanyListDto>(
      `${this.configService.getEndpointUrl()}/batches/manifestr/queueByCompany`,
      {
        params: JSON.parse(JSON.stringify({inductionDepotCode})),
      }
    );
  }

  public;
  getManifests(params: ManifestrSearchParamsDto): Observable<ManifestrManifestGroupDto> {
    return this.http.get<ManifestrManifestGroupDto>(
      `${this.configService.getEndpointUrl()}/batches/manifestr/manifests`,
      {
        params: JSON.parse(JSON.stringify({...params})),
      }
    );
  }

  // This not being used
  public getSingleManifest(groupId: number): Observable<ManifestrManifestEvent> {
    groupId;
    return of(MANIFESTR_MOCK_MANIFEST_GROUP);
  }

  public generateManifest(inductionDepotCode: string): Observable<ManifestrManifestGroupDto> {
    return this.http.post<ManifestrManifestGroupDto>(
      `${this.configService.getEndpointUrl()}/batches/manifestr/generate?InductionDepotCode=${inductionDepotCode}`,
      {}
    );
  }
}
