import {WorkspaceAction, WorkspaceActionType} from './workspace.action';
import {initialWorkspaceState, WorkspaceState} from './workspace.state';

export function workspaceReducer(state = initialWorkspaceState, action: WorkspaceAction): WorkspaceState {
  switch (action.type) {
    case WorkspaceActionType.GET_CONFIGURATION_SUCCESS:
      return {...state, configuration: action.payload.configuration};
    case WorkspaceActionType.UPDATE_CONFIGURATION:
      return {...state, configuration: action.payload.configuration};
    case WorkspaceActionType.GET_APP_SETTINGS_SUCCESS: {
      const appSettingsMap = {...state.appSettingsMap, [action.payload.applicationId]: action.payload.appSettings};
      return {...state, appSettingsMap};
    }
    case WorkspaceActionType.UPDATE_SIDENAV_STATUS: {
      return {...state, sideNavOpened: action.payload.opened};
    }
    case WorkspaceActionType.GET_USER_INFO_SUCCESS:
      return {...state, userInfo: action.payload.userInfo};
    case WorkspaceActionType.CLEAR:
      return initialWorkspaceState;
    case WorkspaceActionType.UPDATE_RIGHT_DRAWER_STATUS:
      return {...state, rightDrawerOpened: action.payload.opened};
    case WorkspaceActionType.UPDATE_PADDING_COLOR:
      return {...state, paddingColor: action.payload.paddingColor};
    case WorkspaceActionType.UPDATE_TIME_ZONE:
      return {...state, timeZone: action.payload.timeZone};
    default:
      return state;
  }
}
