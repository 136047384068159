import {ManifestrManifestListDto, ManifestrManifestListItemDto} from '../dto/manifestr-manifest-list.dto';
import {ManifestrManifestList, ManifestrManifestListItem} from '../model/manifestr-manifest-list';

export function convertManifestrManifestListDtoToModel(dto: ManifestrManifestListDto): ManifestrManifestList {
  return {
    destinations: dto.Destinations.map(item => convertManifestrManifestListItemDtoToModel(item)),
  };
}

export function convertManifestrManifestListItemDtoToModel(
  dto: ManifestrManifestListItemDto
): ManifestrManifestListItem {
  return {
    id: dto.Id,
    displayName: dto.DisplayName,
    batchCount: dto.BatchCount,
    pieceCount: dto.PieceCount,
    weightLb: dto.WeightLB,
    weightKgDisplay: dto.WeightKGDisplay,
    weightKg: dto.WeightKG,
    weightLbDisplay: dto.WeightLBDisplay,
    piecesAdded: dto.PiecesAdded,
  };
}
