<button
  class="menu-button"
  [class.menu-button-active]="open$ | async"
  [matMenuTriggerFor]="menu"
  (menuOpened)="menuOpened()"
  (menuClosed)="menuClosed()"
>
  <img src="/assets/icons/language.svg" class="input-img" />
  <span [class.sim-sun-font]="selectedLanguage === 'cn'">{{ currentLanguage.display }}</span>
</button>

<mat-menu #menu="matMenu" xPosition="before">
  <div class="menu-container">
    <button
      *ngFor="let language of languages"
      class="menu-item"
      mat-menu-item
      (click)="onLanguageChange(language.languageCode)"
    >
      <span
        [class.sim-sun-font]="language.languageCode === 'cn'"
        [class.selected]="language.languageCode === selectedLanguage"
        >{{ language.display || '' }}</span
      >
    </button>
  </div>
</mat-menu>
