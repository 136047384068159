import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {convertCreateGameParamsDtoToModel} from '../../api/converters/convert-create-game-params-dto-to-model';
import {convertPieceInspectorBatchDtoToModel} from '../../api/converters/convert-piece-inspector-batch-dto-to-model';
import {convertPieceInspectorBatchParamsModelToDto} from '../../api/converters/convert-piece-inspector-batch-params-model-to-dto';
import {convertPieceInspectorNewBatchParamsModelToDto} from '../../api/converters/convert-piece-inspector-new-batch-params-model-to-dto';
import {convertPieceInspectorPieceParamsModelToDto} from '../../api/converters/convert-piece-inspector-piece-params-model-to-dto';
import {convertPieceInspectorScanPieceDtoToModel} from '../../api/converters/convert-piece-inspector-scan-piece-dto-to-model';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {BatchApiService} from './../../api/batch-api.service';
import {
  CreateNewGameFromTemplateAction,
  GetPieceInspectorBatchAction,
  GetPieceInspectorBatchesAction,
  GetPieceInspectorBatchesFromScanAction,
  GetPieceInspectorBatchesFromScanSuccessAction,
  GetPieceInspectorBatchesSuccessAction,
  GetPieceInspectorBatchSuccessAction,
  GetPieceInspectorCloseoutBatchesAction,
  GetPieceInspectorCloseoutBatchesSuccessAction,
  GetPieceInspectorGameDetailsAction,
  GetPieceInspectorGameTemplatesAction,
  GetPieceInspectorRecentBatchesAction,
  GetPieceInspectorRecentBatchesSuccessAction,
  GetPieceInspectorRecentGamesAction,
  PieceInspectorActionType,
  PieceInspectorNewBatchAction,
  PieceInspectorNewBatchSuccessAction,
  PieceInspectorUpdateGameAction,
  PieceInspectorUpdateGameSuccessAction,
  ScanPieceInspectorGamePieceAction,
  ScanPieceInspectorPieceAction,
  ScanPieceInspectorPieceSuccessAction,
} from './piece-inspector.actions';

@Injectable()
export class PieceInspectorEffects {
  public getBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetPieceInspectorBatchesAction>(PieceInspectorActionType.GET_BATCHES),
      mergeMap(action => {
        const {params} = action.payload;
        const paramsDto = convertPieceInspectorBatchParamsModelToDto(params);

        return this.batchApiService.getPieceInspectorBatches(paramsDto).pipe(
          map(dto => ({
            batches: dto?.BatchList?.map(batch => convertPieceInspectorBatchDtoToModel(batch)) || [],
            batchesCount: dto?.RecordCount,
          })),
          map(({batches, batchesCount}) => new GetPieceInspectorBatchesSuccessAction({params, batches, batchesCount})),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  public getRecentBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetPieceInspectorRecentBatchesAction>(PieceInspectorActionType.GET_RECENT_BATCHES),
      mergeMap(action => {
        const {params} = action.payload;

        return this.batchApiService.getPieceInspectorRecentBatches(params).pipe(
          map(dto => ({
            batches: dto?.BatchList?.map(batch => convertPieceInspectorBatchDtoToModel(batch)) || [],
          })),
          map(batches => new GetPieceInspectorRecentBatchesSuccessAction(batches)),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  public getBatchFromScan$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetPieceInspectorBatchesFromScanAction>(PieceInspectorActionType.GET_BATCH_FROM_SCAN),
      mergeMap(action => {
        const {barcode, facilityCode, onSuccess, onFailure} = action.payload;

        return this.batchApiService.getPieceInspectorBatchFromScan(barcode, facilityCode).pipe(
          map(dto => ({
            batches: dto?.BatchList?.map(batch => convertPieceInspectorBatchDtoToModel(batch)) || [],
          })),
          mergeMap(batches => [
            new GetPieceInspectorBatchesFromScanSuccessAction(batches),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetPieceInspectorBatchAction>(PieceInspectorActionType.GET_BATCH),
      mergeMap(action => {
        const {batchId, onSuccess, onFailure} = action.payload;
        const encodedBatchId = encodeURIComponent(batchId);

        return this.batchApiService.getPieceInspectorBatch(encodedBatchId).pipe(
          map(dto => convertPieceInspectorBatchDtoToModel(dto)),
          mergeMap(batch => [
            new GetPieceInspectorBatchSuccessAction({batch}),
            ...createCallbackActions(onSuccess, batch),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public scanPiece$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ScanPieceInspectorPieceAction>(PieceInspectorActionType.SCAN_PIECE),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const pieceParams = convertPieceInspectorPieceParamsModelToDto(params);

        return this.batchApiService.scanPieceInspectorPiece(pieceParams).pipe(
          map(dto => convertPieceInspectorScanPieceDtoToModel(dto)),
          mergeMap(piece => [new ScanPieceInspectorPieceSuccessAction({piece}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public newBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PieceInspectorNewBatchAction>(PieceInspectorActionType.NEW_BATCH),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const newBatchParams = convertPieceInspectorNewBatchParamsModelToDto(params);

        return this.batchApiService.pieceInspectorNewBatch(newBatchParams).pipe(
          map(dto => convertPieceInspectorBatchDtoToModel(dto)),
          mergeMap(batch => [new PieceInspectorNewBatchSuccessAction({batch}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getCloseoutBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetPieceInspectorCloseoutBatchesAction>(PieceInspectorActionType.GET_CLOSEOUT_BATCHES),
      mergeMap(action => {
        const {params} = action.payload;
        const paramsDto = convertPieceInspectorBatchParamsModelToDto(params);
        paramsDto.readyForCloseout = true;

        return this.batchApiService.getPieceInspectorCloseoutBatches(paramsDto).pipe(
          map(dto => ({
            batches: dto?.BatchList?.map(batch => convertPieceInspectorBatchDtoToModel(batch)) || [],
            batchesCount: dto?.RecordCount,
          })),
          map(
            ({batches, batchesCount}) =>
              new GetPieceInspectorCloseoutBatchesSuccessAction({params, batches, batchesCount})
          ),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  //GAME

  public getRecentGames$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetPieceInspectorRecentGamesAction>(PieceInspectorActionType.GET_RECENT_GAMES),
      mergeMap(() => {
        return this.batchApiService.getPieceInspectorRecentGames().pipe(
          map(dto => ({
            batches: dto?.BatchList?.map(batch => convertPieceInspectorBatchDtoToModel(batch)) || [],
          })),
          map(batches => new GetPieceInspectorRecentBatchesSuccessAction(batches)),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  public getGameTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetPieceInspectorGameTemplatesAction>(PieceInspectorActionType.GET_GAME_TEMPLATES),
      mergeMap(action => {
        const {params} = action.payload;
        const paramsDto = convertPieceInspectorBatchParamsModelToDto(params);

        return this.batchApiService.getPieceInspectorGameTemplates(paramsDto).pipe(
          map(dto => ({
            batches: dto?.BatchList?.map(batch => convertPieceInspectorBatchDtoToModel(batch)) || [],
            batchesCount: dto?.RecordCount,
          })),
          map(({batches, batchesCount}) => new GetPieceInspectorBatchesSuccessAction({params, batches, batchesCount})),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  public getGameDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetPieceInspectorGameDetailsAction>(PieceInspectorActionType.GET_GAME_DETAILS),
      mergeMap(action => {
        const {batchId, onSuccess, onFailure} = action.payload;
        const encodedBatchId = encodeURIComponent(batchId);

        return this.batchApiService.getPieceInspectorGameDetails(encodedBatchId).pipe(
          map(dto => convertPieceInspectorBatchDtoToModel(dto)),
          mergeMap(batch => [new GetPieceInspectorBatchSuccessAction({batch}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public createGameFromTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateNewGameFromTemplateAction>(PieceInspectorActionType.CREATE_NEW_GAME_FROM_TEMPLATE),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertCreateGameParamsDtoToModel(params);

        return this.batchApiService.createGameFromTemplate(paramsDto).pipe(
          map(dto => convertPieceInspectorBatchDtoToModel(dto)),
          mergeMap(batch => [new GetPieceInspectorBatchSuccessAction({batch}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public updateGame$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PieceInspectorUpdateGameAction>(PieceInspectorActionType.UPDATE_GAME),
      mergeMap(action => {
        const {status, batchNumbers, onSuccess, onFailure} = action.payload;

        return this.batchApiService.updateGame(status, batchNumbers).pipe(
          mergeMap(() => [new PieceInspectorUpdateGameSuccessAction({status}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public scanGamePiece$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ScanPieceInspectorGamePieceAction>(PieceInspectorActionType.SCAN_GAME_PIECE),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const pieceParams = convertPieceInspectorPieceParamsModelToDto(params);

        return this.batchApiService.scanPieceInspectorGamePiece(pieceParams).pipe(
          map(dto => convertPieceInspectorScanPieceDtoToModel(dto)),
          mergeMap(piece => [new ScanPieceInspectorPieceSuccessAction({piece}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(private actions$: Actions, private batchApiService: BatchApiService) {}
}
