import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DraTimezoneDialogComponent} from './dialog/dra-timezone-dialog.component';
import {DraTimezoneSelectorComponent} from './dra-timezone-selector.component';

@NgModule({
  declarations: [DraTimezoneSelectorComponent, DraTimezoneDialogComponent],
  imports: [CommonModule],
  exports: [DraTimezoneSelectorComponent],
})
export class DraTimezoneSelectorModule {}
