import {TrackingActionType, TrackingAction} from './tracking.action';
import {initialTrackingState, TrackingState} from './tracking.state';

export function trackingReducer(state = initialTrackingState, action: TrackingAction): TrackingState {
  switch (action.type) {
    case TrackingActionType.GET_PIECES:
      return {...state, loaded: false};
    case TrackingActionType.GET_PIECES_SUCCESS:
      const piecesMap = {...state.piecesMap, [action.payload.searchValue]: action.payload.pieces};
      return {...state, loaded: true, piecesMap};
    case TrackingActionType.GET_PIECES_ERROR:
      return {...state, error: action.payload.error};
    case TrackingActionType.UPDATE_PIECES_LOADING:
      return {...state, piecesLoading: action.payload.piecesLoading};
    default:
      return state;
  }
}
