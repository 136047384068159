import {Email} from 'src/app/shared/file-search/share-files-dialog/email-form/email-form.component';
import {EmailForm} from '../../models/email-form';
import {EmailFormDto} from '../dto/email-form.dto';

const defaultSubject: string = 'Shared files';
const defaultBody: string = 'Attached to this email are files being shared with you.';

export function convertEmailFormModelToDto(form: EmailForm): EmailFormDto {
  return {
    To: joinArrays(form.to),
    From: joinArrays(form.from),
    CC: joinArrays(form.cc),
    Subject: form.subject || defaultSubject,
    Body: form.body || defaultBody,
    DocumentIDs: form.documentIds,
    AppID: form.applicationId,
  };
}

function joinArrays(arr: Email[]): string {
  return arr.map(email => email.name).join(',');
}
