import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {TrainerModeAddPackagesDialogComponent} from 'src/app/client-dashboard/shared/trainer-mode/add-packages-dialog/trainer-mode-add-packages-dialog.component';
import {ConfigurationApiService} from 'src/app/core/api/configuration-api.service';
import {AuthToken} from 'src/app/core/models/auth-token';
import {Language} from 'src/app/core/models/configuration';
import {LogoutAction} from 'src/app/core/store/auth/auth.action';
import {selectAuthToken} from 'src/app/core/store/auth/auth.selector';
import {selectRouterUrl} from 'src/app/core/store/router/router.selector';
import {Company} from '../../../core/models/application';
import {SelectWorkspaceAction, UpdateTimeZoneAction} from '../../../core/store/workspace/workspace.action';
import {
  selectCompanies,
  selectCurrentApplicationId,
  selectCurrentCompanyId,
  selectTimeZone,
} from '../../../core/store/workspace/workspace.selector';

@Component({
  selector: 'tracking-header',
  templateUrl: './tracking-header.component.html',
  styleUrls: ['./tracking-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackingHeaderComponent implements OnInit {
  @Input()
  public authenticated: boolean;

  @Input()
  public pageTitle: string;

  @Input()
  public showLogout: boolean;

  @Input()
  public trainingMode: boolean;

  @Input()
  public language: string;

  @Input()
  public languages: Language[];

  @Input()
  public url: string;

  @Output()
  public languageChange = new EventEmitter<string>();

  public authToken$: Observable<AuthToken>;
  public companies$: Observable<Company[]>;
  public currentApplicationId$: Observable<string>;
  public currentCompanyId$: Observable<number>;
  private url$: Observable<string>;
  public showSearch$: Observable<boolean>;
  public showLanguageSelector$: Observable<boolean>;
  public showTimeZoneSelector$: Observable<boolean>;
  public selectedTimeZone$: Observable<string>;

  public sortedLanguages$ = new BehaviorSubject<Language[]>([]);

  readonly initialTimeZone = 'User';

  constructor(
    private configurationApiService: ConfigurationApiService,
    private dialog: MatDialog,
    private router: Router,
    private store$: Store<{}>
  ) {}

  public ngOnInit() {
    this.url$ = this.store$.pipe(select(selectRouterUrl));
    this.showSearch$ = this.observeUrl();
    this.showLanguageSelector$ = this.observeLanguage();
    this.showTimeZoneSelector$ = this.observeTimeZone();
    this.currentApplicationId$ = this.store$.pipe(select(selectCurrentApplicationId));
    this.currentCompanyId$ = this.store$.pipe(select(selectCurrentCompanyId));
    this.authToken$ = this.store$.select(selectAuthToken);
    this.companies$ = this.observeCompanies();
    this.selectedTimeZone$ = this.store$.pipe(select(selectTimeZone));
    this.store$.dispatch(new UpdateTimeZoneAction({timeZone: this.initialTimeZone}));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.languages && this.languages) {
      const sortedLanguages = this.languages.sort(this.compare);
      this.sortedLanguages$.next(sortedLanguages);
    }
  }

  compare(a, b) {
    if (a.display < b.display) {
      return -1;
    }
    if (a.display > b.display) {
      return 1;
    }
    return 0;
  }

  private observeUrl(): Observable<boolean> {
    return this.url$.pipe(
      map(url => {
        if (url) {
          return url.startsWith('/tracking') || url.startsWith('/batch-tracking');
        }
      })
    );
  }

  private observeLanguage(): Observable<boolean> {
    return this.url$.pipe(
      map(url => {
        if (url) {
          return url.startsWith('/dashboard');
        }
      })
    );
  }

  private observeTimeZone(): Observable<boolean> {
    return this.url$.pipe(
      map(url => {
        if (url) {
          return url.startsWith('/tracking');
        }
      })
    );
  }

  private observeCompanies(): Observable<Company[]> {
    return this.currentApplicationId$.pipe(
      switchMap(applicationId => this.store$.pipe(select(selectCompanies(applicationId))))
    );
  }

  public onSearchSubmit(valueToSearch: string) {
    this.router.navigate([`/tracking/${valueToSearch}`]);
  }

  public onCompanyChange(companyId: number) {
    combineLatest([this.currentApplicationId$, this.currentCompanyId$])
      .pipe(
        take(1),
        filter(([, currentCompanyId]) => companyId !== currentCompanyId)
      )
      .subscribe(([applicationId]) => this.store$.dispatch(new SelectWorkspaceAction({applicationId, companyId})));
  }

  public onLogoutClick() {
    this.store$.dispatch(new LogoutAction());
  }

  public onLoginClick() {
    this.router.navigate(['/login']);
  }

  public onResetTestData() {
    this.configurationApiService.resetTestData().subscribe(
      () => {
        console.log('Test Data Reset');
      },
      error => {
        console.log(error);
      }
    );
  }

  public onAddPackages() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'add-packages-dialog';
    dialogConfig.maxHeight = '90vh';
    this.dialog.open(TrainerModeAddPackagesDialogComponent, dialogConfig);
  }

  public onResetPackages() {
    console.log();
  }

  public onHideBatches() {
    console.log();
  }

  resetTestDataButtonShouldBeVisible(authToken: AuthToken | null) {
    return this.authenticated && ['timbergrovetest', 'timbergrove'].includes(authToken.userName);
  }

  logoutButtonShouldBeVisible() {
    return this.authenticated && this.showLogout;
  }

  onLanguageChange(value: string) {
    this.languageChange.emit(value);
  }

  onTimeZoneChange(timeZone: string) {
    this.store$.dispatch(new UpdateTimeZoneAction({timeZone}));
  }
}
