import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {BatchFileData} from '../../models/batch-file-data';
import {BatchTransitionType} from '../../models/batch-transition-type';
import {EmailForm} from '../../models/email-form';
import {FileSearchParams} from '../../models/file-search-params';
import {GatewayBatch} from '../../models/gateway-batch';
import {GatewaySummaryParams} from '../../models/gateway-summary-params';
import {PieceDetail} from '../../models/piece-detail';
import {PieceDetailParams} from '../../models/piece-detail-params';
import {PieceInspectorBatch} from '../../models/piece-inspector-batch';
import {PieceRemove} from '../../models/piece-remove';
import {DownloadFilesParams} from '../../models/download-files-params';

export enum GatewayActionType {
  GET_ALL_BATCHES = '[Gateway] Get All Batches',
  GET_ALL_BATCHES_SUCCESS = '[Gateway] Get All Batches :: Success',

  GET_BATCHES = '[Gateway] Get Batches',
  GET_BATCHES_SUCCESS = '[Gateway] Get Batches :: Success',

  GET_GATEWAY_PIECE_DETAILS = '[Gateway] Get Multiple Batches Piece Detail',
  GET_GATEWAY_PIECE_DETAILS_SUCCESS = '[Gateway] Get Multiple Batches Piece Detail :: Success',

  UPDATE_BATCH_STATUS = '[Gateway] Update Batch Status',

  CREATE_BATCH = '[Gateway] Create batch',
  CREATE_BATCH_SUCCESS = '[Gateway] Create batch :: Success',

  ADD_PIECES_TO_BATCH = '[Gateway] Add Pieces To Batch',
  REMOVE_PIECES_FROM_BATCH = '[Gateway] Remove Pieces From Batch',
  REMOVE_PIECES_FROM_MULTIPLE_BATCHES = '[Gateway] Remove Pieces From Multiple Batches',

  GET_FILES = '[Gateway] Get Files',
  GET_FILES_SUCCESS = '[Gateway] Get Files :: Success',
  SHARE_FILES = '[Gateway] Share Files',
  SHARE_FILES_SUCCESS = '[Gateway] Share Files :: Success',

  DOWNLOAD_FILE = '[Gateway] Download File',
  DOWNLOAD_MULTIPLE_FILES = '[Gateway] Download Multiple Files',
  PRINT_FILE = '[Gateway] Print File',
  PRINT_MULTIPLE_PDFS = '[Gateway] Print Multiple PDFs',
  PRINT_MULTIPLE_PDFS_SUCCESS = '[Gateway] Print Multiple PDFs :: Success',
}

export class GetAllGatewayBatchesAction implements Action {
  public readonly type = GatewayActionType.GET_ALL_BATCHES;

  constructor(
    public payload: {
      params: GatewaySummaryParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetAllGatewayBatchesSuccessAction implements Action {
  public readonly type = GatewayActionType.GET_ALL_BATCHES_SUCCESS;

  constructor(public payload: {params: GatewaySummaryParams; batches: GatewayBatch[]; batchesCount: number}) {}
}

export class GetGatewayBatchesAction implements Action {
  public readonly type = GatewayActionType.GET_BATCHES;

  constructor(public payload: {batchNumber: string}) {}
}

export class GetGatewayBatchesSuccessAction implements Action {
  public readonly type = GatewayActionType.GET_BATCHES_SUCCESS;

  constructor(public payload: {batches: GatewayBatch[]}) {}
}

export class GetGatewayPieceDetailsAction implements Action {
  public readonly type = GatewayActionType.GET_GATEWAY_PIECE_DETAILS;

  constructor(public payload: {companyId: number; params: PieceDetailParams}) {}
}

export class GetGatewayPieceDetailsSuccessAction implements Action {
  public readonly type = GatewayActionType.GET_GATEWAY_PIECE_DETAILS_SUCCESS;

  constructor(
    public payload: {companyId: number; params: PieceDetailParams; pieces: PieceDetail[]; piecesCount: number}
  ) {}
}

export class UpdateBatchStatusAction implements Action {
  public readonly type = GatewayActionType.UPDATE_BATCH_STATUS;

  constructor(
    public payload: {
      batchNumbers?: string[];
      pinList?: string[];
      transitionType: BatchTransitionType;
      appId?: string;
      onSuccess: (batch: PieceInspectorBatch) => void;
      onFailure: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class CreateBatchAction implements Action {
  public readonly type = GatewayActionType.CREATE_BATCH;

  constructor(
    public payload: {
      companyId: string;
      shipperId: string;
      piecesIds: {PieceID: number; Pin: string}[];
      onSuccess: (id: string) => void;
      onFailure: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class CreateBatchSuccessAction implements Action {
  public readonly type = GatewayActionType.CREATE_BATCH_SUCCESS;

  constructor(
    public payload: {
      id: string;
    }
  ) {}
}

export class AddPiecesToGatewayBatchAction implements Action {
  public readonly type = GatewayActionType.ADD_PIECES_TO_BATCH;

  constructor(
    public payload: {
      batchNumber: string;
      pieceIds: number[];
      onSuccess: () => void;
      onFailure: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class RemovePiecesFromGatewayBatchAction implements Action {
  public readonly type = GatewayActionType.REMOVE_PIECES_FROM_BATCH;

  constructor(
    public payload: {
      batchNumber: string;
      pieceIds: number[];
      onSuccess: () => void;
      onFailure: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class RemovePiecesFromMultipleGatewayBatchesAction implements Action {
  public readonly type = GatewayActionType.REMOVE_PIECES_FROM_MULTIPLE_BATCHES;

  constructor(
    public payload: {
      batchNumber: string;
      pieces: PieceRemove[];
      onSuccess: () => void;
      onFailure: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetFilesAction implements Action {
  public readonly type = GatewayActionType.GET_FILES;

  constructor(
    public payload: {
      params: FileSearchParams;
      onSuccess: () => void;
      onFailure: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetFilesSuccessAction implements Action {
  public readonly type = GatewayActionType.GET_FILES_SUCCESS;

  constructor(public payload: {params: FileSearchParams; fileData: BatchFileData}) {}
}

export class ShareFilesAction implements Action {
  public readonly type = GatewayActionType.SHARE_FILES;

  constructor(
    public payload: {
      emailForm: EmailForm;
      onSuccess: () => void;
      onFailure: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class ShareFilesSuccessAction implements Action {
  public readonly type = GatewayActionType.SHARE_FILES_SUCCESS;

  constructor(public payload: {emailForm: EmailForm}) {}
}

export class DownloadFileAction implements Action {
  public readonly type = GatewayActionType.DOWNLOAD_FILE;

  constructor(
    public payload: {
      url: string;
      title: string;
      type: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DownloadMultipleFilesAction implements Action {
  public readonly type = GatewayActionType.DOWNLOAD_MULTIPLE_FILES;

  constructor(
    public payload: {
      params: DownloadFilesParams;
      onSuccess?: (blob: Blob) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class PrintFileAction implements Action {
  public readonly type = GatewayActionType.PRINT_FILE;

  constructor(public payload: {url: string}) {}
}

export class PrintMultiplePDFsAction implements Action {
  public readonly type = GatewayActionType.PRINT_MULTIPLE_PDFS;

  constructor(
    public payload: {
      params: DownloadFilesParams;
      onSuccess?: (isZipFile: boolean) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export type GatewayAction =
  | GetAllGatewayBatchesAction
  | GetAllGatewayBatchesSuccessAction
  | GetGatewayBatchesAction
  | GetGatewayBatchesSuccessAction
  | GetGatewayPieceDetailsAction
  | GetGatewayPieceDetailsSuccessAction
  | UpdateBatchStatusAction
  | CreateBatchAction
  | CreateBatchSuccessAction
  | AddPiecesToGatewayBatchAction
  | RemovePiecesFromGatewayBatchAction
  | RemovePiecesFromMultipleGatewayBatchesAction
  | GetFilesAction
  | GetFilesSuccessAction
  | DownloadFileAction
  | PrintFileAction
  | PrintMultiplePDFsAction;
