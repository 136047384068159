import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TrackingDataDto} from './dto/tracking-data.dto';

@Injectable()
export class TrackingApiService {
  constructor(private http: HttpClient) {}

  public getTrackingData(searchValue: string, timeZone?: string): Observable<TrackingDataDto> {
    return this.http.post<TrackingDataDto>('https://api-dra-tracking.purolatorshipping.com/api/tracking', {
      TrackingNumber: searchValue,
      RequestedTimeZone: timeZone || '',
    });
  }
}
