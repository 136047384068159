import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {DraLanguageSelectorComponent} from './dra-language-selector.component';

@NgModule({
  declarations: [DraLanguageSelectorComponent],
  imports: [CommonModule, MatMenuModule],
  exports: [DraLanguageSelectorComponent],
})
export class DraLanguageSelectorModule {}
