import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {BatchTransitionType} from '../models/batch-transition-type';
import {ClientDashboardParams} from '../models/client-dashboard-summary-params';
import {NewContainerParamsDto} from '../models/new-container-form';
import {ConfigurationApiService} from './configuration-api.service';
import {ClientDashboardBatchDto} from './dto/client-dashboard-batch.dto';
import {ClientDashboardBatchesDto} from './dto/client-dashboard-batches.dto';
import {ClientDashboardParamsDto} from './dto/client-dashboard-params.dto';
import {ClientDashboardTrainerPackageParamsDto} from './dto/client-dashboard-trainer-package-params.dto';
import {PieceDetailsPageDto} from './dto/piece-detail.dto';

@Injectable({
  providedIn: 'root',
})
export class ClientDashboardApiService {
  constructor(private http: HttpClient, private configService: ConfigurationApiService) {}

  public getRecentBatches(params: ClientDashboardParams): Observable<ClientDashboardBatchesDto> {
    return this.http.get<ClientDashboardBatchesDto>(`${String(this.configService.getEndpointUrl())}/ClientBatches`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getBatch(batchId: string): Observable<ClientDashboardBatchDto> {
    return this.http.get<ClientDashboardBatchDto>(`${String(this.configService.getEndpointUrl())}/ClientBatch`, {
      params: {batchId},
    });
  }

  public getPieceDetails(params: ClientDashboardParamsDto): Observable<PieceDetailsPageDto> {
    return this.http.get<PieceDetailsPageDto>(`${String(this.configService.getEndpointUrl())}/ClientBatchPieces`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public closeoutByPieces(
    appId: string,
    companyId: number,
    pinList: string[],
    transitionType: string,
    origin?: string
  ): Observable<ClientDashboardBatchDto> {
    return this.http.post<ClientDashboardBatchDto>(`${String(this.configService.getEndpointUrl())}/ClientBatch`, {
      AppId: appId,
      CompanyID: companyId,
      PinList: pinList,
      TransitionType: transitionType,
      Origin: origin,
      DepotCode: 'DTW',
    });
  }

  public closeoutByContainer(batchNumber: string, transitionType: BatchTransitionType, excludedPieceIds: string[]) {
    return this.http.put(`${String(this.configService.getEndpointUrl())}/ClientBatchStatus`, {
      BatchNumber: batchNumber,
      TransitionType: transitionType,
      ExcludePieceIDs: excludedPieceIds,
    });
  }

  public createContainer(params: NewContainerParamsDto): Observable<ClientDashboardBatchDto> {
    return this.http.post<ClientDashboardBatchDto>(
      `${String(this.configService.getEndpointUrl())}/ClientBatch`,
      JSON.parse(JSON.stringify({...params}))
    );
  }

  // ----------Trainer Mode----------
  public getTrainerPackages(params: ClientDashboardTrainerPackageParamsDto): Observable<string[]> {
    console.log(params);
    return of(s());
  }

  createTestPackages(count: number): string[] {
    const arr = [];
    for (let i = 0; i < count; i++) {
      arr.push(`TST00000000${i}`);
    }
    return arr;
  }
}

function s(): string[] {
  const arr = [];
  for (let i = 0; i < 1000; i++) {
    arr.push(`TST00000000${i}`);
  }
  return arr;
}
