import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigurationApiService} from './configuration-api.service';
import {BatchSummaryParamsDto} from './dto/batch-summary-params.dto';
import {BatchSummaryDto} from './dto/batch-summary.dto';
import {PieceDetailParamsExcelDto} from './dto/piece-detail-params-excel.dto';
import {PieceDetailParamsDto} from './dto/piece-detail-params.dto';
import {PieceDetailsPageDto} from './dto/piece-detail.dto';

@Injectable({
  providedIn: 'root',
})
export class StatsApiService {
  constructor(private http: HttpClient, private configService: ConfigurationApiService) {}

  public getBatchSummaries(params?: BatchSummaryParamsDto): Observable<BatchSummaryDto[]> {
    return this.http.get<BatchSummaryDto[]>(`${this.configService.getEndpointUrl()}/StatsSummary`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getPieceDetails(params: PieceDetailParamsDto): Observable<PieceDetailsPageDto> {
    return this.http.get<PieceDetailsPageDto>(`${this.configService.getEndpointUrl()}/StatsDetail`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getPieceDetailsExcelFile(params: PieceDetailParamsExcelDto) {
    return this.http.get(`${this.configService.getEndpointUrl()}/StatsDownload`, {
      params: JSON.parse(JSON.stringify({...params})),
      responseType: 'blob',
    });
  }
}
