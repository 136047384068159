<header
  *ngIf="{
    currentCompanyId: currentCompanyId$ | async,
    showSearch: showSearch$ | async,
    showTimeZoneSelector: showTimeZoneSelector$ | async,
    showLanguageSelector: showLanguageSelector$ | async
  } as state"
  class="do-not-print"
  [class.training-background]="trainingMode"
>
  <div class="header-inner-container" [class.header-training-mode-width]="trainingMode">
    <div class="flex-div">
      <div class="logo-container flex-div">
        <img src="assets/purolator-logo-01.svg" class="logo" />
      </div>
      <span class="page-title">{{ pageTitle === 'Client Dashboard' ? 'Shipment ManageR' : pageTitle }}</span>
    </div>

    <!-- <div *ngIf="offlineMode" class="offline-mode">OFFLINE MODE</div> -->

    <div *ngIf="trainingMode" class="training-mode-info flex-div">
      <img src="assets/icons/info-training.svg" class="info-icon" />
      <span>Training Mode</span>
    </div>

    <div class="flex-div">
      <div *ngIf="resetTestDataButtonShouldBeVisible(authToken$ | async)" data-testid="reset-test-data-button">
        <button mat-flat-button (click)="onResetTestData()" class="logout-button">Reset Test Data</button>
      </div>

      <dra-language-selector
        *ngIf="languages?.length && state.showLanguageSelector"
        [languages]="sortedLanguages$ | async"
        [selectedLanguage]="language.toLowerCase()"
        (valueChange)="onLanguageChange($event)"
        class="mr-16"
        [class.mr-32]="!state.showTimeZoneSelector"
      ></dra-language-selector>

      <dra-timezone-selector
        *ngIf="state.showTimeZoneSelector"
        [selectedTimeZone]="selectedTimeZone$ | async"
        (valueChange)="onTimeZoneChange($event)"
        class="mr-32"
      ></dra-timezone-selector>

      <div *ngIf="logoutButtonShouldBeVisible()">
        <button mat-flat-button (click)="onLogoutClick()" class="logout-button">
          Logout
          <img src="assets/icons/tracking-icons/logout-white.svg" class="logout-icon" />
        </button>
      </div>
    </div>
  </div>
</header>
