import {EventRecordrAction, EventRecordrActionType} from './event-recordr.action';
import {EventRecordrState, initialEventRecordrState} from './event-recordr.state';

export function eventRecordrReducer(state = initialEventRecordrState, action: EventRecordrAction): EventRecordrState {
  switch (action.type) {
    case EventRecordrActionType.GET_BATCHES_SUCCESS:
      if (action.payload.getMore) {
        return {...state, batches: state.batches.concat(action.payload.batches)};
      }
      return {...state, batches: action.payload.batches};
    case EventRecordrActionType.GET_BATCH_SUCCESS:
      return {...state, batch: action.payload.batch};
    case EventRecordrActionType.GET_PIECES_SUCCESS:
      return {...state, pieces: action.payload.pieces};
    case EventRecordrActionType.GET_SHIPMENT_MANAGER_BATCHES_SUCCESS:
      return {...state, shipmentManagerBatches: action.payload.batches};
    case EventRecordrActionType.GET_SHIPMENT_MANAGER_BATCH_SUCCESS:
      return {...state, shipmentManagerBatch: action.payload.batch};
    case EventRecordrActionType.SET_PARAMS:
      return {...state, params: action.payload.params};
    case EventRecordrActionType.SET_SCROLL_POSITION:
      return {...state, scrollPosition: action.payload.scrollPosition};
    default:
      return state;
  }
}
