import {createSelector} from '@ngrx/store';
import {AppState} from '../app.state';
import {selectRouterQueryParam, selectRouterUrl} from '../router/router.selector';
import {ApplicationId} from '../../models/application';

export const selectWorkspaceState = (state: AppState) => state.workspace;

export const selectConfiguration = createSelector(selectWorkspaceState, state => state.configuration);

export const selectConfigurationLoaded = createSelector(selectWorkspaceState, state => !!state.configuration);

export const selectApplicationCategories = createSelector(
  selectConfiguration,
  configuration => configuration?.applicationCategories || []
);

export const selectApplications = createSelector(
  selectConfiguration,
  configuration => configuration?.applications || []
);

export const selectApplication = (applicationId: string) =>
  createSelector(selectApplications, applications => applications.find(app => app.id === applicationId));

export const selectAppSettingsMap = createSelector(selectWorkspaceState, state => state.appSettingsMap);

export const selectAppSettings = (applicationId: string) =>
  createSelector(
    selectAppSettingsMap,
    selectApplication(applicationId),
    (appSettingsMap, application) => appSettingsMap[applicationId] || application
  );

export const selectBatchOperations = (applicationId: string) =>
  createSelector(selectAppSettings(applicationId), appSettings => appSettings?.batchOperations || []);

export const selectCompanies = (applicationId: string) =>
  createSelector(selectAppSettings(applicationId), appSettings => appSettings?.companies || []);
export const selectCompany = (applicationId: string, companyId: number) =>
  createSelector(selectCompanies(applicationId), companies => companies?.find(company => company.id === companyId));

export const selectCurrentApplicationId = createSelector(selectRouterUrl, url => {
  if (url?.startsWith('/batch-tracking')) {
    return ApplicationId.BatchTracking;
  } else if (url?.startsWith('/gateway')) {
    return ApplicationId.GatewayBatchManager;
  } else if (url?.startsWith('/file-search')) {
    return ApplicationId.Documents;
  } else if (url?.startsWith('/batch-inspectr')) {
    return ApplicationId.BatchInspectR;
  } else if (url?.startsWith('/piece-inspectr')) {
    return ApplicationId.PieceInspectR;
  } else if (url?.startsWith('/dashboard')) {
    return ApplicationId.ClientDashboard;
  }
});

export const selectCurrentCompanyId = createSelector(
  selectRouterQueryParam('company'),
  companyId => companyId && Number(companyId)
);

export const selectCurrentCompany = (applicationId: string) =>
  createSelector(selectCompanies(applicationId), selectCurrentCompanyId, (companies, companyId) =>
    companies?.find(company => company.id === companyId)
  );

export const selectAllBatchTypes = createSelector(selectConfiguration, config => config.batchTypes);

export const selectSidenavOpened = createSelector(selectWorkspaceState, state => state.sideNavOpened);

export const selectRightDrawerOpened = createSelector(selectWorkspaceState, state => state.rightDrawerOpened);

export const selectUserInfo = createSelector(selectWorkspaceState, state => state.userInfo);

export const selectPageWrapperPaddingColor = createSelector(selectWorkspaceState, state => state.paddingColor);

export const selectOfflineMode = createSelector(selectWorkspaceState, state => state.offlineMode);

export const selectTimeZone = createSelector(selectWorkspaceState, state => state.timeZone);
