import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {
  removeAuthTokenFromLocalStorage,
  writeAuthTokenToLocalStorage,
} from '../../../shared/utils/auth/auth-token.utils';
import {AuthApiService} from '../../api/auth-api.service';
import {convertAuthTokenDtoToModel} from '../../api/converters/convert-auth-token-dto-to-model';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {ClearWorkspaceAction} from '../workspace/workspace.action';
import {AuthActionType, LoginAction, LoginSuccessAction, LogoutAction} from './auth.action';
import {environment} from '../../../../environments/environment';
import {ClearAllAction} from '../common/common.action';

@Injectable()
export class AuthEffects {
  public login$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoginAction>(AuthActionType.LOGIN),
      mergeMap(action => {
        const {username, password, url, onSuccess, onFailure} = action.payload;
        return this.authApiService.login(username, password, url).pipe(
          map(dto => convertAuthTokenDtoToModel(dto)),
          tap(value => {
            environment.apiUrl = `${value.endpointUrl}/api`;
          }),
          tap(token => writeAuthTokenToLocalStorage(token)),
          mergeMap(token => [
            new LoginSuccessAction({token}),
            new ClearWorkspaceAction(),
            ...createCallbackActions(onSuccess, token),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LogoutAction>(AuthActionType.LOGOUT),
      tap(() => removeAuthTokenFromLocalStorage()),
      tap(() => from(this.router.navigate(['/login']))),
      map(() => new ClearAllAction())
    )
  );

  constructor(private actions$: Actions, private authApiService: AuthApiService, private router: Router) {}
}
