import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {ManifestrBatch} from 'src/app/manifestr/shared/model/manifestr-batch';
import {ManifestrDestinationListItem} from 'src/app/manifestr/shared/model/manifestr-destination-list';
import {ManifestrDispatchLocation} from 'src/app/manifestr/shared/model/manifestr-dispatch-location';
import {ManifestrManifestEvent, ManifestrManifestGroup} from 'src/app/manifestr/shared/model/manifestr-manifest-group';
import {ManifestrManifestListItem} from 'src/app/manifestr/shared/model/manifestr-manifest-list';
import {ManifestrQueueCompanyList} from 'src/app/manifestr/shared/model/manifestr-queue-company-list';
import {ManifestrSearchParams} from 'src/app/manifestr/shared/model/manifestr-search-params';
import {ManifestrEditQueueParams} from 'src/app/manifestr/shared/model/manifestr-select-batches-params';

export enum ManifestrActionType {
  GET_DISPATCH_GATEWAYS = '[Manifestr] Get Dispatch Gateways',
  GET_DISPATCH_GATEWAYS_SUCCESS = '[Manifestr] Get Dispatch Gateways :: Success',
  CLEAR_DISPATCH_GATEWAYS = '[Manifestr] Clear Dispatch Gateways',

  GET_BATCHES = '[Manifestr] Get Batches',
  GET_BATCHES_SUCCESS = '[Manifestr] Get Batches :: Success',
  CLEAR_BATCHES = '[Manifestr] Clear Batches',

  SELECT_BATCHES = '[Manifestr] Select Batches',
  SELECT_BATCHES_SUCCESS = '[Manifestr] Select Batches :: Success',

  REMOVE_BATCH = '[Manifestr] Remove Batch',
  REMOVE_BATCH_SUCCESS = '[Manifestr] Remove Batch :: Success',

  GET_QUEUE_BY_DESTINATION = '[Manifestr] Get Queue By Destination',
  GET_QUEUE_BY_DESTINATION_SUCCESS = '[Manifestr] Get Queue By Destination :: Success',

  GET_QUEUE_BY_COMPANY = '[Manifestr] Get Queue By Company',
  GET_QUEUE_BY_COMPANY_SUCCESS = '[Manifestr] Get Queue By Company :: Success',

  GET_MANIFESTS = '[Manifestr] Get Manifests',
  GET_MANIFESTS_SUCCESS = '[Manifestr] Get Manifests :: Success',

  GET_SINGLE_MANIFEST = '[Manifestr] Get Single Manifest',
  GET_SINGLE_MANIFEST_SUCCESS = '[Manifestr] Get Single Manifest :: Success',

  GENERATE_MANIFEST = '[Manifestr] Generate Manifest',
  GENERATE_MANIFEST_SUCCESS = '[Manifestr] Generate Manifest :: Success',

  UPDATE_BATCH_SEARCH_PARAMS = '[Manifestr] Update Batch Search Params',

  UPDATE_MANIFEST_PARAMS = '[Manifestr] Update Manifest Params',
}

export class GetManifestrDispatchGatewaysAction implements Action {
  public readonly type = ManifestrActionType.GET_DISPATCH_GATEWAYS;

  constructor(
    public payload: {
      params: ManifestrSearchParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetManifestrDispatchGatewaysSuccessAction implements Action {
  public readonly type = ManifestrActionType.GET_DISPATCH_GATEWAYS_SUCCESS;

  constructor(public payload: {dispatchLocations: ManifestrDispatchLocation[]}) {}
}

export class ClearManifestrDispatchGatewaysAction implements Action {
  public readonly type = ManifestrActionType.CLEAR_DISPATCH_GATEWAYS;

  constructor(public payload: {}) {}
}

export class GetManifestrBatchesAction implements Action {
  public readonly type = ManifestrActionType.GET_BATCHES;

  constructor(
    public payload: {
      params: ManifestrSearchParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetManifestrBatchesSuccessAction implements Action {
  public readonly type = ManifestrActionType.GET_BATCHES_SUCCESS;

  constructor(public payload: {batches: ManifestrBatch[]; params: ManifestrSearchParams}) {}
}

export class ClearManifestrBatchesAction implements Action {
  public readonly type = ManifestrActionType.CLEAR_BATCHES;

  constructor(public payload: {}) {}
}

export class SelectManifestrBatchesAction implements Action {
  public readonly type = ManifestrActionType.SELECT_BATCHES;

  constructor(
    public payload: {
      params: ManifestrEditQueueParams;
      onSuccess?: (manifestList: ManifestrManifestListItem[]) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class SelectManifestrBatchesSuccessAction implements Action {
  public readonly type = ManifestrActionType.SELECT_BATCHES_SUCCESS;

  constructor(public payload: {manifestList: ManifestrManifestListItem[]}) {}
}

export class RemoveManifestrBatchAction implements Action {
  public readonly type = ManifestrActionType.REMOVE_BATCH;

  constructor(
    public payload: {
      params: ManifestrEditQueueParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class RemoveManifestrBatchSuccessAction implements Action {
  public readonly type = ManifestrActionType.REMOVE_BATCH_SUCCESS;

  constructor(public payload: {}) {}
}

export class GetManifestrQueueByDestinationAction implements Action {
  public readonly type = ManifestrActionType.GET_QUEUE_BY_DESTINATION;

  constructor(
    public payload: {
      inductionDepotCode: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetManifestrQueueByDestinationSuccessAction implements Action {
  public readonly type = ManifestrActionType.GET_QUEUE_BY_DESTINATION_SUCCESS;

  constructor(public payload: {destinationList: ManifestrDestinationListItem[]}) {}
}

export class GetManifestrQueueByCompanyAction implements Action {
  public readonly type = ManifestrActionType.GET_QUEUE_BY_COMPANY;

  constructor(
    public payload: {
      inductionDepotCode: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetManifestrQueueByCompanySuccessAction implements Action {
  public readonly type = ManifestrActionType.GET_QUEUE_BY_COMPANY_SUCCESS;

  constructor(public payload: {queueCompanyList: ManifestrQueueCompanyList}) {}
}

export class GetManifestrManifestsAction implements Action {
  public readonly type = ManifestrActionType.GET_MANIFESTS;

  constructor(
    public payload: {
      params: ManifestrSearchParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetManifestrManifestsSuccessAction implements Action {
  public readonly type = ManifestrActionType.GET_MANIFESTS_SUCCESS;

  constructor(public payload: {manifestGroup: ManifestrManifestGroup}) {}
}

export class GetSingleManifestrManifestAction implements Action {
  public readonly type = ManifestrActionType.GET_SINGLE_MANIFEST;

  constructor(
    public payload: {
      groupId: number;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetSingleManifestrManifestSuccessAction implements Action {
  public readonly type = ManifestrActionType.GET_SINGLE_MANIFEST_SUCCESS;

  constructor(public payload: {manifestEvent: ManifestrManifestEvent}) {}
}

export class GenereateManifestrManifestAction implements Action {
  public readonly type = ManifestrActionType.GENERATE_MANIFEST;

  constructor(
    public payload: {
      inductionDepotCode: string;
      onSuccess?: (manifestGroup: ManifestrManifestGroup) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GenereateManifestrManifestSuccessAction implements Action {
  public readonly type = ManifestrActionType.GENERATE_MANIFEST_SUCCESS;

  constructor(public payload: {manifestGroup: ManifestrManifestGroup}) {}
}

export class UpdateManifestrBatchSearchParamsAction implements Action {
  public readonly type = ManifestrActionType.UPDATE_BATCH_SEARCH_PARAMS;

  constructor(public payload: {params: ManifestrSearchParams}) {}
}

export class UpdateManifestrManifestParamsAction implements Action {
  public readonly type = ManifestrActionType.UPDATE_MANIFEST_PARAMS;

  constructor(public payload: {params: ManifestrSearchParams}) {}
}

export type ManifestrAction =
  | GetManifestrBatchesAction
  | GetManifestrBatchesSuccessAction
  | ClearManifestrDispatchGatewaysAction
  | SelectManifestrBatchesAction
  | SelectManifestrBatchesSuccessAction
  | ClearManifestrBatchesAction
  | GetManifestrDispatchGatewaysAction
  | GetManifestrDispatchGatewaysSuccessAction
  | GetManifestrQueueByDestinationAction
  | GetManifestrQueueByDestinationSuccessAction
  | GetManifestrQueueByCompanyAction
  | GetManifestrQueueByCompanySuccessAction
  | GetManifestrManifestsAction
  | GetManifestrManifestsSuccessAction
  | GetSingleManifestrManifestAction
  | GetSingleManifestrManifestSuccessAction
  | GenereateManifestrManifestAction
  | GenereateManifestrManifestSuccessAction
  | UpdateManifestrBatchSearchParamsAction
  | UpdateManifestrManifestParamsAction;
