import {Action} from '@ngrx/store';
import {Piece} from '../model/piece';
import {HttpErrorResponse} from '@angular/common/http';

export enum TrackingActionType {
  GET_PIECES = '[Tracking] Get Pieces',
  GET_PIECES_SUCCESS = '[Tracking] Get Pieces Success',
  UPDATE_PIECES_LOADING = '[Tracking] Update Pieces Loading',
  GET_PIECES_ERROR = '[Tracking] Get Pieces :: Error',
}

export class GetPiecesAction implements Action {
  public readonly type = TrackingActionType.GET_PIECES;

  constructor(
    public payload: {
      searchValue: string;
      timeZone?: string;
      onSuccess?: () => void;
      onFailure?: (err: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetPiecesSuccessAction implements Action {
  public readonly type = TrackingActionType.GET_PIECES_SUCCESS;

  constructor(public payload: {pieces: Piece[]; searchValue: string}) {}
}

export class UpdatePiecesLoadingAction implements Action {
  public readonly type = TrackingActionType.UPDATE_PIECES_LOADING;

  constructor(public payload: {piecesLoading: boolean}) {}
}

export class GetPiecesErrorAction implements Action {
  public readonly type = TrackingActionType.GET_PIECES_ERROR;

  constructor(public payload: {error: HttpErrorResponse}) {}
}

export type TrackingAction =
  | GetPiecesAction
  | GetPiecesSuccessAction
  | UpdatePiecesLoadingAction
  | GetPiecesErrorAction;
