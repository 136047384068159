import {HttpErrorResponse} from '@angular/common/http';
import {Piece} from '../model/piece';

export interface TrackingState {
  loaded: boolean;
  piecesMap: Record<string, Piece[]>;
  error: HttpErrorResponse;
  piecesLoading: boolean;
}

export const initialTrackingState: TrackingState = {
  loaded: false,
  piecesMap: {},
  error: null,
  piecesLoading: false,
};
