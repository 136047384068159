export interface Application {
  appGuid: string;
  id: ApplicationId;
  caption: string;
  description: string;
  companies: Company[];
  pieceTypes: SearchOption[];
  statusTypes: SearchOption[];
  clearanceStatusTypes: SearchOption[];
  clearanceTypes: SearchOption[];
  batchOperations?: string[];
  locationMatrix?: LocationMatrix;
  showCloseOutByContainer?: boolean;
  showCloseOutByPieces?: boolean;
  showCreateNewContainer?: boolean;
  showViewContainers?: boolean;
  puroPostInductions?: PuroPostInduction[];
  transitStatusTypes?: SearchOption[];
  showConsigneeInformation: boolean;
}

export interface LocationMatrix {
  companies: LocationMatrixCompany[];
  locationRecords: LocationMatrixLocationRecord[];
}

export interface LocationMatrixCompany {
  accountGroups: AccountGroup[];
  companyId: number;
}

export interface AccountGroup {
  groupName: string;
  originCountries: OriginCountry[];
}

export interface OriginCountry {
  countryCode: string;
  originLocations: OriginLocation[];
}

export interface OriginLocation {
  inductionLocations: string[];
  locationCode: string;
}

export interface LocationMatrixLocationRecord {
  accountGroup: string;
  companyId: number;
  inductionLocation: string;
  originCountry: string;
  originId: number;
  originLocation: string;
}

export enum ApplicationId {
  BatchTracking = 'BatchTracking',
  GatewayBatchManager = 'GBM',
  Documents = 'Documents',
  BatchInspectR = 'BatchInspectR',
  PieceInspectR = 'PieceInspectR',
  ClearanceInspectR = 'ClearanceInspectR',
  Games = 'Games',
  ClientDashboard = 'ClientDashboard',
  ClientDashboardTrainer = 'ClientDashboardTrainer',
  ManifestR = 'ManifestR',
  PieceTracking = 'PieceTracking',
  EventRecordR = 'EventRecordR',
  ReturnR = 'ReturnR',
}

export interface Company {
  id: number;
  description: string;
  facilities: SearchOption[];
  origins: SearchOrigin[];
  filterGroups: SearchFilterGroup[];
  filters: SearchFilter[];
}

export interface SearchOption {
  code: string;
  description: string;
}

export interface SearchOrigin {
  code: string;
  description: string;
  facilityCode: string;
}

export interface SearchFilterGroup {
  code: number;
  description: string;
}

export interface SearchFilter {
  id: number;
  groupID: number;
  label: string;
  labelGroup: string;
  statusType: number;
  clearanceStatusType?: boolean;
  sequence: number;
}

export interface ScanActivity {
  id: number;
  code: string;
  description: string;
}

export interface PuroPostInduction {
  code: string;
  description: string;
}
