import {ManifestrBatch} from 'src/app/manifestr/shared/model/manifestr-batch';
import {ManifestrDestinationListItem} from 'src/app/manifestr/shared/model/manifestr-destination-list';
import {ManifestrDispatchLocation} from 'src/app/manifestr/shared/model/manifestr-dispatch-location';
import {ManifestrManifestEvent, ManifestrManifestGroup} from 'src/app/manifestr/shared/model/manifestr-manifest-group';
import {ManifestrManifestListItem} from 'src/app/manifestr/shared/model/manifestr-manifest-list';
import {ManifestrQueueCompanyList} from 'src/app/manifestr/shared/model/manifestr-queue-company-list';
import {ManifestrSearchParams} from 'src/app/manifestr/shared/model/manifestr-search-params';

export interface ManifestrState {
  dispatchLocations: ManifestrDispatchLocation[];
  batches: ManifestrBatch[];
  queryBatchesMap: Record<string, ManifestrBatch[]>;
  manifestList: ManifestrManifestListItem[];
  queueCompanyList: ManifestrQueueCompanyList;
  queueDestinationList: ManifestrDestinationListItem[];
  manifestGroup: ManifestrManifestGroup;
  singleManifestEvent: ManifestrManifestEvent;
  batchSearchParams: ManifestrSearchParams;
  manifestHistoryParams: ManifestrSearchParams;
}

export const initialManifestrState: ManifestrState = {
  dispatchLocations: [],
  batches: null,
  queryBatchesMap: {},
  manifestList: [],
  queueCompanyList: null,
  queueDestinationList: null,
  manifestGroup: null,
  singleManifestEvent: null,
  batchSearchParams: {batchTypes: '1014'},
  manifestHistoryParams: {batchTypes: '1014'},
};
