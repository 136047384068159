import {EventRecordrShipmentManagerBatch} from '../../shared/model/event-recrodr-shipment-manager-batch';

export const SHIPMENT_MANAGER_MOCK_DATA: EventRecordrShipmentManagerBatch[] = [
  {
    status: 'ready for departure',
    batchId: 2349786234,
    date: '14 MAY 2023',
    pieceCount: 433,
    company: 'Suzies Mailorder Burritos',
    events: [
      {
        date: '14 MAY 2023',
        status: 'Clearance Shortage',
        displayNumber: 'SHORT 3293487',
        pieceCount: 178,
        weight: '200kg',
        pieces: [
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
        ],
      },
      {
        date: '14 MAY 2023',
        status: 'Clearance PASS',
        displayNumber: 'PASS 3293487',
        pieceCount: 178,
        weight: '200kg',
        pieces: [
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
        ],
      },
      {
        date: '14 MAY 2023',
        status: 'Clearance Container',
        displayNumber: 'AWB 3293487',
        pieceCount: 178,
        weight: '200kg',
        pieces: [
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
        ],
      },
      {
        date: '14 MAY 2023',
        status: 'Clearance Inducted',
        displayNumber: 'IND 3293487',
        pieceCount: 178,
        weight: '200kg',
        pieces: [
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
        ],
      },
      {
        date: '14 MAY 2023',
        status: 'Clearance Inducted',
        displayNumber: 'IND 3293487',
        pieceCount: 178,
        weight: '200kg',
        pieces: [
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
        ],
      },
      {
        date: '14 MAY 2023',
        status: 'Clearance Inducted',
        displayNumber: 'IND 3293487',
        pieceCount: 178,
        weight: '200kg',
        pieces: [
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
        ],
      },
      {
        date: '14 MAY 2023',
        status: 'Clearance Inducted',
        displayNumber: 'IND 3293487',
        pieceCount: 178,
        weight: '200kg',
        pieces: [
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
        ],
      },
      {
        date: '14 MAY 2023',
        status: 'Clearance Inducted',
        displayNumber: 'IND 3293487',
        pieceCount: 178,
        weight: '200kg',
        pieces: [
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
        ],
      },
      {
        date: '14 MAY 2023',
        status: 'Clearance Inducted',
        displayNumber: 'IND 3293487',
        pieceCount: 178,
        weight: '200kg',
        pieces: [
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
        ],
      },
      {
        date: '14 MAY 2023',
        status: 'Clearance Inducted',
        displayNumber: 'IND 3293487',
        pieceCount: 178,
        weight: '200kg',
        pieces: [
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
        ],
      },
      {
        date: '14 MAY 2023',
        status: 'Clearance Inducted',
        displayNumber: 'IND 3293487',
        pieceCount: 178,
        weight: '200kg',
        pieces: [
          {
            pin: '2342342342342',
            orderNumber: '423523',
            destinationPostalCode: 'K6A2GS',
            weight: '20lbs',
            status: 'Awaiting clearance third',
          },
        ],
      },
    ],
  },
  {
    status: 'in transit to canada',
    batchId: 2349786234,
    date: '14 MAY 2023',
    pieceCount: 433,
    company: 'Suzies Mailorder Burritos',
  },
  {
    status: 'arrived in canada',
    batchId: 2349786234,
    date: '14 MAY 2023',
    pieceCount: 433,
    company: 'Suzies Mailorder Burritos',
  },
  {
    status: 'ready for departure',
    batchId: 2349786234,
    date: '14 MAY 2023',
    pieceCount: 433,
    company: 'Suzies Mailorder Burritos',
  },
  {
    status: 'ready for departure',
    batchId: 2349786234,
    date: '14 MAY 2023',
    pieceCount: 433,
    company: 'Suzies Mailorder Burritos',
  },
  {
    status: 'in transit to canada',
    batchId: 2349786234,
    date: '14 MAY 2023',
    pieceCount: 433,
    company: 'Suzies Mailorder Burritos',
  },
  {
    status: 'arrived in canada',
    batchId: 2349786234,
    date: '14 MAY 2023',
    pieceCount: 433,
    company: 'Suzies Mailorder Burritos',
  },
  {
    status: 'ready for departure',
    batchId: 2349786234,
    date: '14 MAY 2023',
    pieceCount: 433,
    company: 'Suzies Mailorder Burritos',
  },
];
