import {EnvironmentName} from 'dra-lib';
import {DraEnvironment} from './dra-environment';

export const environment: DraEnvironment = {
  loginUrl: 'https://api-dra-production.purolatorshipping.com/api',
  apiUrl: 'https://api-dra-production.purolatorshipping.com/api',
  envName: EnvironmentName.Production,
  mapboxToken: 'pk.eyJ1Ijoid29ybGRwYWstZHJwdyIsImEiOiJjazBlN3VhcjEwZjdzM2RybnhjZm9sdWljIn0.-qh9vJLyoHejzlkX_bC48Q',
  production: true,
  sentryDsn: 'https://52e049fe423847adbbae2b0a029ddba6@sentry.io/5182576',
};

export const eventRecordrApiUrl: string = 'https://hkydcuc2kj.execute-api.us-east-1.amazonaws.com/production';
